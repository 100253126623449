import _get from 'lodash/get';
import { getContenfulFilters } from 'containers/Main/utils';
import ctf from 'utils/contentfulService';
import { mapEntryWithTranslationLink } from 'containers/ResourceController/utils';

const getArticles = async (
  _key,
  {
    type,
    shouldFilterShortArticles = false,
    clientExcludedTopic,
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
    payload,
    localeFilters,
    featuresConfig,
  },
) => {
  const contentfulFilters = getContenfulFilters(
    clientExcludedTopic,
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
  );
  const finalLocaleFilters =
    _get(localeFilters, 'locale') === 'en-US' ? {} : localeFilters;

  const shortArticlesFilters = shouldFilterShortArticles
    ? {
        'fields.wordCount[gte]': _get(
          featuresConfig,
          'config.articlesMinimumWordCount',
          400,
        ),
      }
    : {};

  const response = await ctf.getEntries({
    content_type: type,
    'fields.reviewStatus': 'Accepted',
    limit: 5,
    include: 1,
    ...contentfulFilters,
    ...payload,
    ...finalLocaleFilters,
    ...shortArticlesFilters,
  });

  const updatedItems = response?.items.map(mapEntryWithTranslationLink);

  return {
    ...response,
    items: updatedItems,
  };
};

export default getArticles;
