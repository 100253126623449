/**
 *
 * RichTextReactRenderer
 *
 */

import React, { useMemo, useRef } from 'react';
import _classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _flattenDeep from 'lodash/flattenDeep';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import _isUndefined from 'lodash/isUndefined';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import VisibilitySensor from 'react-visibility-sensor';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ForwardArrow from '@material-ui/icons/KeyboardArrowRight';
import ChevronIcon from 'images/common/right-chevron.svg';
import Colors from 'utils/colors';
import Mixpanel from 'utils/mixpanelService';
import { isJson } from 'utils/stringUtils';
import {
  makeSelectClientDetails,
  makeSelectTextDirection,
} from 'containers/Main/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setLeavingModal } from 'containers/Main/actions';
import PracticeWidget from 'components/PracticeWidget';
import { getTypeUrl } from 'components/ResourceItemSelector/utils';
import RemindMeAnchor from './RemindMeAnchor';
import HtmlMedia from './HtmlMedia';
import ReadMoreLessWrapper from './ReadMoreLessWrapper';
import { contentTypeMapping, getElementWordCount } from './utils';

const TITLE_TYPES = [
  'book',
  'events',
  'podcast',
  'topics',
  'video',
  'landing',
  'list',
  'onlineProgram',
];

const getLinkMapping = (type, node) => {
  let finalType = type;
  if (type === 'source') {
    finalType = _get(node, 'data.target.fields.type');
  }

  return contentTypeMapping[finalType];
};

const getLinkByType = type => {
  const linkMapping = {
    person: 'people',
    book: 'books',
    application: 'apps',
    podcast: 'podcasts',
    activity: 'articles',
    video: 'videos',
    organization: 'organizations',
    reads: 'articles',
    program: 'onlinePrograms',
    list: 'lists',
    News: 'articles',
    FAQ: 'faqs',
    'Online Programs': 'programs',
  };

  return linkMapping[type] || type.toLowerCase();
};

const useStyles = makeStyles(theme => ({
  customFontsize: {
    '& p': {
      [theme.breakpoints.up('xs')]: {
        fontSize: ({ fontSize }) => _get(fontSize, 'mobile', '0.875rem'),
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: ({ fontSize }) => _get(fontSize, 'tablet', '1rem'),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: ({ fontSize }) => _get(fontSize, 'desktop', '1.375rem'),
      },
    },
  },
  richTextWrapper: {
    direction: ({ textDirection }) => textDirection,
    color: ({ color }) => color || theme.palette.primary.contrastText,
    '& a': {
      color: ({ linkColor }) => linkColor || Colors.link,
    },
    '& .embedded-link': {
      textDecoration: 'none',
    },
    '& .embedded-link-inactive': {
      textDecoration: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
    '& video': {
      width: '100%',
    },
    '& img': {
      maxWidth: '100%',
    },
    '& p,h1,h2,h3,h4,h5,h6': {
      marginTop: '1em',
      marginBottom: '1em',
      minHeight: '1.5em',
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    textAlign: ({ justify, centered }) =>
      justify ? 'justify' : centered ? 'center' : 'left',
  },
  richTextWrapperWithHeaders: {
    '& h1': theme.typography.h1,
    '& h2': theme.typography.h2,
    '& h3': theme.typography.h3,
    '& h4': theme.typography.h4,
    '& h5': theme.typography.h5,
    '& h6': theme.typography.h6,
  },
  richTextWrapperWithoutHeaders: {
    '& h1': {
      fontSize: '2em',
      lineHeight: '1.43',
    },
    '& h2': {
      fontSize: '1.5em',
      lineHeight: '1.43',
    },
    '& h3': {
      fontSize: '1.17em',
      lineHeight: '1.43',
    },
    '& h4': {
      fontSize: '1em',
      lineHeight: '1.43',
    },
    '& h5': {
      fontSize: '0.83em',
      lineHeight: '1.43',
    },
    '& h6': {
      fontSize: '0.67em',
      lineHeight: '1.43',
    },
  },
  landingLinks: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& img': {
      width: 'auto !important',
      marginRight: 8,
    },
  },
  recommendBotWrapper: {
    backgroundColor: '#EBEBEB',
    padding: '12px 24px',
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      '& span': {
        ...theme.typography.body2,
      },
    },
    '& ul': {
      marginTop: 4,
      marginBottom: 4,
      listStyleType: 'none',
      paddingInlineStart: '12px',
      paddingLeft: 12,
      '& span': {
        ...theme.typography.pBold,
      },
    },
  },
  recommendBotHeader: {
    ...theme.typography.body2,
    fontSize: '1.0625rem !important',
    lineHeight: '1.35 !important',
  },
  recommendBotLi: {
    ...theme.typography.pBold,
    fontSize: '1.0625rem !important',
    lineHeight: '1.35 !important',
  },
  hiddenSection: {
    visibility: 'hidden',
    height: '0.5em',
    marginTop: '-1em',
    marginBottom: '-1em',
  },
  heroLinks: {
    fontWeight: 'bolder',
    fontSize: '18px',
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '464px',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('550')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('284')]: {
      fontSize: '13px',
    },
  },
  imgWrapper: {
    marginInlineStart: 40,
    marginInlineEnd: 40,
  },
}));

const defaultLinkNodeEntryRender = ({
  link,
  value,
  isLinkActive,
  onCMLinkClick,
}) => {
  if (!isLinkActive) {
    return <span>{value}</span>;
  }

  return (
    <Link
      to={link}
      onClick={e => {
        if (onCMLinkClick) onCMLinkClick(e, link);
      }}
      className="embedded-link"
    >
      {value}
    </Link>
  );
};

function RichTextReactRenderer(props) {
  const {
    resourceData,
    name,
    contentType,
    subtype,
    blog,
    data,
    color,
    textualLink = null,
    limit,
    paragraphVariant = 'body1',
    component,
    extraData,
    justify = false,
    centered = false,
    withHeaderStyles = false,
    withHeroLinksStyles = false,
    linkAsPlainText = false,
    hideReadText,
    withButton,
    buttonHandler,
    expand,
    isLandingPage,
    withIcon,
    readMoreLessHandler,
    isDescription,
    className,
    promotedResources = <></>,
    triggerResetShortMode,
    renderLinkNodeEntry = defaultLinkNodeEntryRender,
    onCMLinkClick,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const wordCount = useRef(0);
  const sensor250Set = useRef(false);
  const sensor500Set = useRef(false);
  const sensor750Set = useRef(false);
  const sensor1000Set = useRef(false);
  const clientDetails = useSelector(makeSelectClientDetails());
  const textDirection = useSelector(makeSelectTextDirection());
  const classes = useStyles({
    centered,
    color,
    justify,
    textDirection,
    ...rest,
  });

  const excludeResourcePaths = (
    _get(clientDetails, 'excludeResourceTypes') || []
  )
    .map(type => getTypeUrl(type))
    .reduce((accumulator, value) => [...accumulator, ...value], []);
  const excludeSlugs = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
    [],
  )
    .concat(_get(clientDetails, 'excludeTopicCollection.items', []))
    .map(item => item.slug);

  const trackRecommendBotEvent = (style, token, url) => {
    const [, pageType, pageSlug] = window.location.pathname.split('/');
    const [, exitType, exitSlug] = url.split('/');
    Mixpanel.track('RecommendBot Link Clicked', {
      style,
      token,
      pageType,
      pageSlug,
      exitType,
      exitSlug,
    });
  };

  const paragraphLinks = useMemo(() => {
    if (
      !extraData ||
      extraData.stopRecommendBot ||
      !extraData.recommendBotConfig
    )
      return null;

    const paragraphs = extraData.recommendBotConfig.paragraphs
      .map(p => {
        const content = p.content
          .map(token => {
            const customParagraphRegex = /\*\|(.*?)\|\*/gm;
            let match = token.match(customParagraphRegex);
            if (match) match = match[0].substring(2, match[0].length - 2);

            const finalChild = token
              .split(customParagraphRegex)
              .map(piece => {
                if (piece === '') return null;

                if (match.includes(piece)) {
                  const [key, count] = piece.split('-');
                  if (
                    !_isEmpty(extraData[key]) &&
                    !_isEmpty(extraData[key][count - 1])
                  ) {
                    const entry = extraData[key][count - 1];
                    const entryName =
                      entry.name ||
                      entry.title ||
                      _get(entry, 'fields.name') ||
                      _get(entry, 'fields.title');
                    const entryType =
                      entry.type || entry.sys.contentType.sys.id;
                    const entrySlug = entry.slug || _get(entry, 'fields.slug');
                    const url = `/${getLinkByType(entryType)}/${entrySlug}`;
                    return (
                      <Link
                        to={url}
                        onClick={() =>
                          trackRecommendBotEvent('list', piece, url)
                        }
                        className={classes.recommendBotLi}
                        key={url}
                      >
                        {entryName}
                      </Link>
                    );
                  }

                  return -1;
                }

                return piece;
              })
              .filter(childEl => childEl !== null);

            if (finalChild.length === 0 || finalChild.includes(-1)) return null;

            return <li>{finalChild}</li>;
          })
          .filter(el => el !== null);
        if (content.length > 0) {
          return (
            <>
              <Typography className={classes.recommendBotHeader} key={p.header}>
                {p.header}
              </Typography>
              <ul>{content}</ul>
            </>
          );
        }
        return null;
      })
      .filter(el => el !== null);

    if (paragraphs.length > 0) {
      return <div className={classes.recommendBotWrapper}>{paragraphs}</div>;
    }

    return null;
  }, [extraData]);

  const trackExitEvent = url => {
    Mixpanel.track('Rich Text Link Exited', {
      page: window.location.pathname,
      exitPage: url,
    });
  };

  const getEntryDisplay = node => {
    const { file = {} } = _get(node, 'data.target.fields') || {};
    // check if it contains media
    if (file && _has(file, 'contentType')) {
      return (
        <HtmlMedia
          name={name}
          contentType={contentType}
          blog={blog}
          subtype={subtype}
          node={node}
          classes={classes}
        />
      );
    }
    if (
      [INLINES.EMBEDDED_ENTRY, BLOCKS.EMBEDDED_ENTRY].includes(
        _get(node, 'nodeType'),
      ) &&
      _get(node, 'data.target.sys.contentType.sys.id') === 'practice'
    ) {
      return <PracticeWidget data={_get(node, 'data.target')} inline />;
    }

    const nodeContentType = _get(node, [
      'data',
      'target',
      'sys',
      'contentType',
      'sys',
      'id',
    ]);
    const reviewStatus = _get(node, [
      'data',
      'target',
      'fields',
      'reviewStatus',
    ]);

    const linkMapping = getLinkMapping(nodeContentType, node);
    const isLinkActive =
      (nodeContentType === 'landing' || reviewStatus === 'Accepted') &&
      !excludeResourcePaths.includes(linkMapping) &&
      !excludeSlugs.includes(_get(node, 'data.target.fields.slug'));
    const link = isLinkActive
      ? `/${linkMapping}/${_get(node, 'data.target.fields.slug')}`
      : '#';
    const hasContentTypes = !_isEmpty(
      _get(node, 'data.target.sys.contentType'),
    );
    const hasContent = !_isEmpty(_get(node, 'content'));
    const contentValue = hasContent
      ? _get(node, 'content').map(item => {
          let value = _get(item, 'value', '');
          item.marks.forEach(mark => {
            if (mark.type === 'underline') value = <u>{value}</u>;
            else if (mark.type === 'bold') value = <b>{value}</b>;
            else if (mark.type === 'italic') value = <i>{value}</i>;
          });
          return value;
        })
      : undefined; // for custom value
    const typeContent = hasContentTypes && nodeContentType;
    const finalType = TITLE_TYPES.includes(typeContent)
      ? 'title'
      : typeContent === 'source'
      ? 'article'
      : 'name'; // check what field to use, title or name or article(source)

    if (!_has(node.data.target.fields, finalType)) return null;
    const finalValue = hasContent
      ? contentValue
      : node.data.target.fields[finalType];

    return linkAsPlainText ? (
      <span>{finalValue}</span>
    ) : (
      renderLinkNodeEntry({
        link,
        isLinkActive,
        value: finalValue,
        node,
        onCMLinkClick,
      })
    );
  };

  const onVisibilityChange = (isVisible, count) => {
    if (isVisible) {
      let type = contentType;
      if (contentType === 'article') {
        if (blog) type = 'blog';
        else if (subtype === 'News') type = 'news';
      }
      Mixpanel.track('Resource Page - ScrollTo', {
        path: window.location.pathname,
        section: 'description',
        type,
        wordCount: count,
        name,
      });
    }
  };

  const renderTextNode = variant => (_, children) => {
    if (Array.isArray(children) && children.length === 1 && children[0] === '')
      return null;

    const supRegex = /<sup>(.*?)<\/sup>/gm;
    const subRegex = /<sub>(.*?)<\/sub>/gm;
    const extraRegex = /<extra(.*?)\/>/gm;
    const customParagraphRegex = /\*\|(.*?)\|\*/gm;

    const finalChildren = children.map((el, index) => {
      // TOKEN FOR RENDERING PROMOTIONAL RESOURCES
      if (typeof el === 'string' && el === '{{promotionalResources}}') {
        return promotedResources;
      }
      if (typeof el === 'string' && customParagraphRegex.test(el)) {
        if (extraData.stopRecommendBot) return null;

        const phrases = el.split(/[.?!]/g);
        const separators = el.match(/[.?!]/g);
        let customContent = [];
        phrases.forEach(phrase => {
          const match = phrase.match(customParagraphRegex);
          if (match) customContent.push(...match);
        });
        customContent = customContent.map(customEl =>
          customEl.substring(2, customEl.length - 2),
        );

        let hasContent = false;

        const finalChild = phrases.map((phrase, phraseIndex) => {
          if (phrase === '') return '';
          const pieces = phrase.split(customParagraphRegex);
          const multiWildcard = pieces.length > 3;
          const phraseChildren = [];
          let phraseStarted = false;
          for (let i = 0; i < pieces.length; i += 1) {
            const piece = pieces[i];

            if (customContent.includes(piece)) {
              const [key, count, text, connector] = piece.split('-');
              const finalText = text && text.substring(1, text.length - 1);
              const hasConnector = connector && phraseStarted;
              if (!_isEmpty(extraData[key])) {
                const entryList = extraData[key].slice(
                  0,
                  count === 'n' ? undefined : Number(count),
                );

                hasContent = true;
                const finalPhrase = entryList.map((extraEl, extraIndex) => {
                  const entryName =
                    extraEl.name ||
                    extraEl.title ||
                    _get(extraEl, 'fields.name') ||
                    _get(extraEl, 'fields.title');
                  const entryType =
                    extraEl.type || extraEl.sys.contentType.sys.id;
                  const entrySlug =
                    extraEl.slug || _get(extraEl, 'fields.slug');
                  const url = `/${getLinkByType(entryType)}/${entrySlug}`;
                  return (
                    <span>
                      {extraIndex !== 0 &&
                        extraIndex !== entryList.length - 1 &&
                        ', '}
                      {extraIndex !== 0 &&
                        extraIndex === entryList.length - 1 &&
                        ' and '}
                      <Link
                        to={url}
                        onClick={() =>
                          trackRecommendBotEvent('sentence', piece, url)
                        }
                      >
                        {entryName}
                      </Link>
                    </span>
                  );
                });
                const initialArray = [];
                if (finalText && hasConnector)
                  initialArray.push(
                    <span>{` ${connector.substring(
                      1,
                      connector.length - 1,
                    )} ${finalText} `}</span>,
                  );
                else if (finalText)
                  initialArray.push(
                    <span>{` ${finalText
                      .charAt(0)
                      .toUpperCase()}${finalText.slice(1)} `}</span>,
                  );

                phraseChildren.push(initialArray.concat(finalPhrase));
                phraseStarted = true;
              } else if (multiWildcard) {
                phraseChildren.push('');
              } else {
                phraseChildren.push(null);
              }
            } else phraseChildren.push(piece);
          }
          if (phraseChildren.includes(null)) return null;
          if (
            phraseChildren.filter(piece => piece !== '' && piece !== ' ')
              .length === 0
          )
            return null;
          return (
            <>
              {phraseChildren}
              {separators[phraseIndex]}
            </>
          );
        });
        if (!hasContent) return null;

        return (
          <span>
            {_flattenDeep(finalChild.filter(childEl => childEl !== null))}
          </span>
        );
      }
      if (typeof el === 'string' && extraRegex.test(el)) {
        const typeRegex = /type="(.*?)"/;
        const [, type] = el.match(typeRegex);

        return _get(extraData, type) || null;
      }

      let finalEl = el;
      if (typeof finalEl === 'string' && finalEl.includes('<clientName>')) {
        finalEl = finalEl.replace(/<clientName>/g, _get(clientDetails, 'name'));
      }
      if (
        typeof finalEl === 'string' &&
        finalEl.includes('<clientFormalShortName>')
      ) {
        finalEl = finalEl.replace(
          /<clientFormalShortName>/g,
          _get(clientDetails, 'formalShortName'),
        );
      }
      if (
        (typeof finalEl === 'string' && finalEl.includes('<sup>')) ||
        (Array.isArray(finalEl) &&
          _some(finalEl, piece => {
            if (typeof piece === 'string') return piece.includes('<sub>');
            return piece;
          }))
      ) {
        if (Array.isArray(finalEl)) {
          finalEl = finalEl.map(elPiece => {
            if (typeof elPiece === 'string' && elPiece.includes('<sup>')) {
              const supContent = [];
              const child = elPiece;
              let match = supRegex.exec(child);
              while (match != null) {
                supContent.push(match[1]);
                match = supRegex.exec(child);
              }

              return elPiece.split(supRegex).map(piece => {
                if (supContent.includes(piece)) {
                  return <sup key={piece}>{piece}</sup>;
                }
                return piece;
              });
            }
            return elPiece;
          });
        } else {
          const supContent = [];
          const child = finalEl;
          let match = supRegex.exec(child);
          while (match != null) {
            supContent.push(match[1]);
            match = supRegex.exec(child);
          }

          finalEl = finalEl.split(supRegex).map(piece => {
            if (supContent.includes(piece)) {
              return <sup key={piece}>{piece}</sup>;
            }
            return piece;
          });
        }
      }
      if (
        (typeof finalEl === 'string' && finalEl.includes('<sub>')) ||
        (Array.isArray(finalEl) &&
          _some(finalEl, piece => {
            if (typeof piece === 'string') return piece.includes('<sub>');
            return piece;
          }))
      ) {
        if (Array.isArray(finalEl)) {
          finalEl = finalEl.map(elPiece => {
            if (typeof elPiece === 'string' && elPiece.includes('<sub>')) {
              const subContent = [];
              const child = elPiece;
              let match = subRegex.exec(child);
              while (match != null) {
                subContent.push(match[1]);
                match = subRegex.exec(child);
              }

              return elPiece.split(subRegex).map(piece => {
                if (subContent.includes(piece)) {
                  return <sub key={piece}>{piece}</sub>;
                }
                return piece;
              });
            }
            return elPiece;
          });
        } else {
          const subContent = [];
          const child = finalEl;
          let match = subRegex.exec(child);
          while (match != null) {
            subContent.push(match[1]);
            match = subRegex.exec(child);
          }

          finalEl = finalEl.split(subRegex).map(piece => {
            if (subContent.includes(piece)) {
              return <sub key={piece}>{piece}</sub>;
            }
            return piece;
          });
        }
      }

      return <span key={index}>{finalEl}</span>;
    });
    if (finalChildren.filter(child => child !== null).length === 0) return null;

    if (isDescription) {
      const counts = finalChildren.map(child => getElementWordCount(child));
      wordCount.current += counts.reduce((acc, current) => acc + current);
      if (wordCount.current > 250 && !sensor250Set.current) {
        sensor250Set.current = true;
        return (
          <>
            <Typography component={component} variant={variant}>
              {finalChildren}
            </Typography>
            <VisibilitySensor
              onChange={e => onVisibilityChange(e, 250)}
              scrollCheck
              scrollDelay={500}
            >
              <div className={classes.hiddenSection} />
            </VisibilitySensor>
          </>
        );
      }
      if (wordCount.current > 500 && !sensor500Set.current) {
        sensor500Set.current = true;
        return (
          <>
            <Typography component={component} variant={variant}>
              {finalChildren}
            </Typography>
            <VisibilitySensor
              onChange={e => onVisibilityChange(e, 500)}
              scrollCheck
              scrollDelay={500}
            >
              <div className={classes.hiddenSection} />
            </VisibilitySensor>
          </>
        );
      }
      if (wordCount.current > 750 && !sensor750Set.current) {
        sensor750Set.current = true;
        return (
          <>
            <Typography component={component} variant={variant}>
              {finalChildren}
            </Typography>
            <VisibilitySensor
              onChange={e => onVisibilityChange(e, 750)}
              scrollCheck
              scrollDelay={500}
            >
              <div className={classes.hiddenSection} />
            </VisibilitySensor>
          </>
        );
      }
      if (wordCount.current > 1000 && !sensor1000Set.current) {
        sensor1000Set.current = true;
        return (
          <>
            <Typography component={component} variant={variant}>
              {finalChildren}
            </Typography>
            <VisibilitySensor
              onChange={e => onVisibilityChange(e, 1000)}
              scrollCheck
              scrollDelay={500}
            >
              <div className={classes.hiddenSection} />
            </VisibilitySensor>
          </>
        );
      }
    }

    return (
      <Typography component={component} variant={variant}>
        {finalChildren}
      </Typography>
    );
  };

  const options = {
    renderMark: {
      [MARKS.ITALIC]: text => {
        const supRegex = /<sup>(.*?)<\/sup>/gm;
        const subRegex = /<sub>(.*?)<\/sub>/gm;

        let finalText = text;
        if (typeof finalText === 'string' && finalText.includes('<sup>')) {
          const supContent = [];
          const child = finalText;
          let match = supRegex.exec(child);
          while (match != null) {
            supContent.push(match[1]);
            match = supRegex.exec(child);
          }

          const finalChild = finalText.split(supRegex).map(piece => {
            if (supContent.includes(piece)) {
              return <sup key={piece}>{piece}</sup>;
            }
            return piece;
          });

          finalText = finalChild;
        }
        if (typeof finalText === 'string' && finalText.includes('<sub>')) {
          const subContent = [];
          const child = finalText;
          let match = subRegex.exec(child);
          while (match != null) {
            subContent.push(match[1]);
            match = subRegex.exec(child);
          }

          const finalChild = finalText.split(subRegex).map(piece => {
            if (subContent.includes(piece)) {
              return <sub key={piece}>{piece}</sub>;
            }
            return piece;
          });

          finalText = finalChild;
        }

        return <i>{finalText}</i>;
      },
      [MARKS.BOLD]: text => {
        const supRegex = /<sup>(.*?)<\/sup>/gm;
        const subRegex = /<sub>(.*?)<\/sub>/gm;

        let finalText = text;
        if (typeof finalText === 'string' && finalText.includes('<sup>')) {
          const supContent = [];
          const child = finalText;
          let match = supRegex.exec(child);
          while (match != null) {
            supContent.push(match[1]);
            match = supRegex.exec(child);
          }

          const finalChild = finalText.split(supRegex).map(piece => {
            if (supContent.includes(piece)) {
              return <sup key={piece}>{piece}</sup>;
            }
            return piece;
          });

          finalText = finalChild;
        }
        if (typeof finalText === 'string' && finalText.includes('<sub>')) {
          const subContent = [];
          const child = finalText;
          let match = subRegex.exec(child);
          while (match != null) {
            subContent.push(match[1]);
            match = subRegex.exec(child);
          }

          const finalChild = finalText.split(subRegex).map(piece => {
            if (subContent.includes(piece)) {
              return <sub key={piece}>{piece}</sub>;
            }
            return piece;
          });

          finalText = finalChild;
        }

        return <b>{finalText}</b>;
      },
      ..._get(rest.options, 'renderMark'),
    },
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: node => getEntryDisplay(node),
      [INLINES.ASSET_HYPERLINK]: node => getEntryDisplay(node),
      [INLINES.ENTRY_HYPERLINK]: node => getEntryDisplay(node),
      [INLINES.HYPERLINK]: (node, children) => {
        const nodeHasValue = !_isEmpty(children);
        if (!nodeHasValue) return null;
        const splittedUrl = node.data.uri.split(' ');
        let link;
        let target;
        if (splittedUrl.length > 2) {
          [, link] = splittedUrl[1].split('=');
          [, target] = splittedUrl[2].split('=');
        } else {
          [link] = splittedUrl;
        }

        if (_isUndefined(link)) {
          return false;
        }
        const cleanLink = link.replace(/^"(.*)"$/, '$1');
        const isCMLink =
          cleanLink.startsWith('/') ||
          cleanLink.startsWith('https://crediblemind.com');
        const cleanTarget =
          (target && target.replace('>', '').replace(/^"(.*)"$/, '$1')) ||
          (!isCMLink && '_blank') ||
          undefined;

        const handleCMLinkClick = e => {
          if (onCMLinkClick) onCMLinkClick(e, cleanLink);
        };

        if (isCMLink) {
          if (isLandingPage) {
            return (
              <div className={classes.landingLinks}>
                <img src={ChevronIcon} alt="" className={classes.chevron} />
                <Link
                  onClick={handleCMLinkClick}
                  className={classes.heroLinks}
                  to={cleanLink
                    .replace('https://crediblemind.com', '')
                    .replace('https://www.crediblemind.com', '')}
                >
                  {children}
                </Link>
              </div>
            );
          }

          return linkAsPlainText ? (
            <span>{children}</span>
          ) : (
            <Link
              onClick={handleCMLinkClick}
              to={cleanLink
                .replace('https://crediblemind.com', '')
                .replace('https://www.crediblemind.com', '')}
            >
              {children}
            </Link>
          );
        }
        if (withHeroLinksStyles && isLandingPage) {
          const openUrl = anchorUrl => e => {
            e.preventDefault();

            trackExitEvent(cleanLink);
            dispatch(
              setLeavingModal({
                visible: true,
                url: anchorUrl,
                resourceType: 'pure-url',
              }),
            );
          };
          return (
            <a
              className={classes.heroLinks}
              href={cleanLink}
              target={cleanTarget}
              onClick={openUrl(cleanLink)}
            >
              {children}
            </a>
          );
        }
        if (isLandingPage) {
          const openUrl = anchorUrl => e => {
            e.preventDefault();

            trackExitEvent(cleanLink);
            dispatch(
              setLeavingModal({
                visible: true,
                url: anchorUrl,
                resourceType: 'pure-url',
              }),
            );
          };

          return (
            <a
              href={cleanLink}
              target={cleanTarget}
              onClick={openUrl(cleanLink)}
            >
              {children}
            </a>
          );
        }
        if (node.shouldTriggerRemindMe) {
          return (
            <RemindMeAnchor
              url={cleanLink}
              target={cleanTarget}
              label={children}
              data={resourceData}
            />
          );
        }

        return linkAsPlainText ? (
          <span>{children}</span>
        ) : (
          <a
            href={cleanLink}
            target={cleanTarget}
            onClick={() => trackExitEvent(cleanLink)}
          >
            {children}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: node => getEntryDisplay(node),
      [BLOCKS.EMBEDDED_ENTRY]: node => getEntryDisplay(node),
      [BLOCKS.PARAGRAPH]: renderTextNode(paragraphVariant),
      [BLOCKS.HEADING_1]: renderTextNode('h1'),
      [BLOCKS.HEADING_2]: renderTextNode('h2'),
      [BLOCKS.HEADING_3]: renderTextNode('h3'),
      [BLOCKS.HEADING_4]: renderTextNode('h4'),
      [BLOCKS.HEADING_5]: renderTextNode('h5'),
      [BLOCKS.HEADING_6]: renderTextNode('h6'),
      ..._get(rest.options, 'renderNode'),
      ...(_get(rest.options, 'getRenderNode')?.({
        getEntryDisplay,
        renderTextNode,
      }) || {}),
    },
  };
  const parsedData = useMemo(() => {
    if (_isEmpty(data)) return null;

    wordCount.current = 0;
    sensor250Set.current = false;
    sensor500Set.current = false;
    sensor750Set.current = false;
    sensor1000Set.current = false;

    if (typeof data === 'object')
      return documentToReactComponents(data, options);
    if (isJson(data))
      return documentToReactComponents(JSON.parse(data), options);
    return data;
  }, [data, options]);
  if (_isEmpty(parsedData)) return null;

  return (
    <Grid
      item
      xs={12}
      className={_classNames(
        {
          [classes.richTextWrapper]: true,
          [classes.richTextWrapperWithHeaders]: withHeaderStyles,
          [classes.richTextWrapperWithoutHeaders]: !withHeaderStyles,
          [classes.customFontsize]: !!rest.fontSize,
        },
        className,
      )}
    >
      <ReadMoreLessWrapper
        limit={limit}
        hideReadText={hideReadText}
        color={color}
        buttonHandler={buttonHandler}
        expand={expand}
        withButton={withButton}
        readMoreLessHandler={readMoreLessHandler}
        triggerResetShortMode={triggerResetShortMode}
      >
        {parsedData}
        {textualLink}
        {paragraphLinks}
        {withIcon && <ForwardArrow />}
      </ReadMoreLessWrapper>
    </Grid>
  );
}

RichTextReactRenderer.propTypes = {
  isDescription: PropTypes.bool,
  data: PropTypes.any,
  limit: PropTypes.number,
  name: PropTypes.string,
  contentType: PropTypes.string,
  subtype: PropTypes.string,
  justify: PropTypes.bool,
  centered: PropTypes.bool,
  blog: PropTypes.bool,
  paragraphVariant: PropTypes.string,
  component: PropTypes.string,
  color: PropTypes.string,
  hideReadText: PropTypes.bool,
  withButton: PropTypes.bool,
  buttonHandler: PropTypes.func,
  expand: PropTypes.bool,
  isLandingPage: PropTypes.bool,
  withIcon: PropTypes.bool,
  readMoreLessHandler: PropTypes.func,
  extraData: PropTypes.object,
  resourceData: PropTypes.object,
  textualLink: PropTypes.node,
  withHeaderStyles: PropTypes.bool,
  withHeroLinksStyles: PropTypes.bool,
  linkAsPlainText: PropTypes.bool,
  triggerResetShortMode: PropTypes.any,
  onCMLinkClick: PropTypes.func,
};

RichTextReactRenderer.defaultProps = {
  isLandingPage: false,
  withIcon: false,
};

export default RichTextReactRenderer;
