import { select } from 'redux-saga/effects';
import {
  makeSelectClientDetails,
  makeSelectLanguage,
} from 'containers/Main/selectors';
import update from 'immutability-helper';
import { makeSelectClientLanding } from 'containers/ClientLanding/selectors';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import { isLocalStorageAllowed } from 'utils/stringUtils';

export function* getAlgoliaRatingQuery() {
  const clientDetails = yield select(makeSelectClientDetails());
  if (!clientDetails) return '';
  const {
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
  } = clientDetails;
  let algoliaRatingCutOffQuery = '';

  if (expertRatingCutoff) {
    algoliaRatingCutOffQuery += ` AND cutoffExpertRating >= ${expertRatingCutoff}`;
  }
  if (averageRatingCutoff) {
    const resourcesWeightings = _get(
      clientDetails,
      'metadata.algoliaResourcesWeight',
    );
    let field = 'cutoffAvgRating';
    if (resourcesWeightings === '75-25') field = 'cutoffAvgRating7525';
    else if (resourcesWeightings === '90-10') field = 'cutoffAvgRating9010';

    algoliaRatingCutOffQuery += ` AND ${field} >= ${averageRatingCutoff}`;
  }
  if (userRatingCutoff) {
    algoliaRatingCutOffQuery += ` AND cutoffUserRating >= ${userRatingCutoff}`;
  }
  return algoliaRatingCutOffQuery;
}

export function* getContentfulRatingQuery() {
  const clientDetails = yield select(makeSelectClientDetails());
  let contentfulRatingQuery = {};
  if (!_isEmpty(clientDetails)) {
    const {
      averageRatingCutoff,
      expertRatingCutoff,
      userRatingCutoff,
    } = clientDetails;
    if (expertRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedExpertRating[gte]': expertRatingCutoff,
      };
    }
    if (averageRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedAverageRating[gte]': averageRatingCutoff,
      };
    }
    if (userRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedUserRating[gte]': userRatingCutoff,
      };
    }
  }
  return contentfulRatingQuery;
}

export function* getTopicsIdQuery() {
  const clientDetails = yield select(makeSelectClientDetails());
  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  const excludedTopicsId = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => topic.sys.id)
    : [];
  return excludedTopicsId;
}

export function* getTopicsSlugQuery() {
  const clientDetails = yield select(makeSelectClientDetails());
  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  const excludedTopicsSlug = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => topic.slug)
    : [];
  return excludedTopicsSlug;
}

export function* getAlgoliaTopicsQuery(fieldName, excludeOnly = true) {
  const clientDetails = yield select(makeSelectClientDetails());
  const clientLanding = yield select(makeSelectClientLanding());
  let algoliaTopicsQuery = '';
  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  let fieldToUse = 'title';
  if (fieldName === 'filterTopics' || fieldName === 'allTopics.slug')
    fieldToUse = 'slug';
  const excludedTopicField = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => _get(topic, [fieldToUse]))
    : [];
  if (!_isEmpty(excludedTopicField)) {
    _forEach(excludedTopicField, value => {
      algoliaTopicsQuery += ` AND NOT ${fieldName}:'${value}'`;
    });
  }

  if (!excludeOnly && !_isEmpty(clientDetails)) {
    const topicsCollections = _get(clientLanding, 'topTopicsCollection.items');
    if (!_isEmpty(topicsCollections)) {
      algoliaTopicsQuery += 'AND ';
      _forEach(topicsCollections, ({ title }, index) => {
        algoliaTopicsQuery += `allTopics.slug:'${title}'`;
        if (index !== topicsCollections.length - 1) {
          algoliaTopicsQuery += ' OR ';
        }
      });
    }
  }

  return algoliaTopicsQuery;
}

export function* getContentfulTopicsQuery(isTopic) {
  const clientDetails = yield select(makeSelectClientDetails());
  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  let contentfulTopicsQuery = {};
  const excludedTopicIDs = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => topic.sys.id)
    : [];
  if (!_isEmpty(excludedTopicIDs)) {
    if (isTopic)
      contentfulTopicsQuery = {
        'sys.id[nin]': excludedTopicIDs.join(','),
      };
    else
      contentfulTopicsQuery = {
        'fields.topics.sys.id[nin]': excludedTopicIDs.join(','),
      };
  }
  return contentfulTopicsQuery;
}

export function* getContentfulAssessmentsQuery() {
  const clientDetails = yield select(makeSelectClientDetails());
  const clientExcludedAssessments = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
  );
  let contentfulAssessmentsQuery = {};
  const excludedAssessmentIDs = !_isEmpty(clientExcludedAssessments)
    ? clientExcludedAssessments.map(assessment => assessment.sys.id)
    : [];
  if (!_isEmpty(excludedAssessmentIDs)) {
    contentfulAssessmentsQuery = {
      'sys.id[nin]': excludedAssessmentIDs.join(','),
    };
  }
  return contentfulAssessmentsQuery;
}

export function* getAlgoliaAssessmentQuery() {
  const clientDetails = yield select(makeSelectClientDetails());
  const clientExcludedAssessments = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
  );
  let algoliaAssessmentsQuery = '';
  if (clientExcludedAssessments) {
    _forEach(clientExcludedAssessments, ({ slug }) => {
      algoliaAssessmentsQuery += ` AND NOT slug:'${slug}'`;
    });
  }
  return algoliaAssessmentsQuery;
}

export function getContenfulFilters(
  clientExcludedTopic,
  averageRatingCutoff,
  expertRatingCutoff,
  userRatingCutoff,
  isTopic,
  dismissRatingQuery = false,
) {
  // topics query
  let contentfulTopicsQuery = {};
  const excludedTopicIDs = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => topic.sys.id)
    : [];
  if (!_isEmpty(excludedTopicIDs)) {
    if (isTopic)
      contentfulTopicsQuery = {
        'sys.id[nin]': excludedTopicIDs.join(','),
      };
    else
      contentfulTopicsQuery = {
        'fields.topics.sys.id[nin]': excludedTopicIDs.join(','),
      };
  }

  let contentfulRatingQuery = {};
  if (!dismissRatingQuery) {
    // rating query
    if (expertRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedExpertRating[gte]': expertRatingCutoff,
      };
    }
    if (averageRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedAverageRating[gte]': averageRatingCutoff,
      };
    }
    if (userRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedUserRating[gte]': userRatingCutoff,
      };
    }
  }

  return {
    ...contentfulTopicsQuery,
    ...contentfulRatingQuery,
  };
}
export function getAlgoliaFilters({
  clientExcludedTopic = [],
  averageRatingCutoff,
  expertRatingCutoff,
  userRatingCutoff,
  resourcesWeightings,
  isTopic = false,
  topicsField = 'topics',
  excludedAssessments = [],
  profileFilters,
}) {
  // topics query
  let algoliaTopicsQuery = '';
  let topicsFieldToUse = 'title';
  if (topicsField === 'filterTopics' || topicsField === 'allTopics.slug')
    topicsFieldToUse = 'slug';
  const excludedTopicField = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => _get(topic, [topicsFieldToUse]))
    : [];
  if (!_isEmpty(excludedTopicField) && isTopic) {
    _forEach(excludedTopicField, value => {
      algoliaTopicsQuery += ` AND NOT ${topicsField}:'${value}'`;
    });
  }

  // rating query
  let algoliaRatingCutOffQuery = '';
  if (expertRatingCutoff) {
    algoliaRatingCutOffQuery += ` AND cutoffExpertRating >= ${expertRatingCutoff}`;
  }
  if (averageRatingCutoff) {
    let field = 'cutoffAvgRating';
    if (resourcesWeightings === '75-25') field = 'cutoffAvgRating7525';
    else if (resourcesWeightings === '90-10') field = 'cutoffAvgRating9010';

    algoliaRatingCutOffQuery += ` AND ${field} >= ${averageRatingCutoff}`;
  }
  if (userRatingCutoff) {
    algoliaRatingCutOffQuery += ` AND cutoffUserRating >= ${userRatingCutoff}`;
  }

  let algoliaAssessmentsQuery = '';
  if (excludedAssessments) {
    _forEach(excludedAssessments, ({ slug }) => {
      algoliaAssessmentsQuery += ` AND NOT slug:'${slug}'`;
    });
  }

  let algoliaRequiredTagsQuery = ' AND requiredTags:"none"';
  if (!_isEmpty(_get(profileFilters, 'insuranceTags'))) {
    algoliaRequiredTagsQuery = ` AND (requiredTags:"none" OR ${profileFilters.insuranceTags
      .map(tag => `requiredTags:"${tag.id}"`)
      .join(' OR ')})`;
  }

  let algoliaStateQuery = '';
  if (!_isEmpty(_get(profileFilters, 'state'))) {
    if (profileFilters.state !== 'all') {
      algoliaStateQuery = ` AND (state:"all" OR state:"${profileFilters.state}")`;
    }
  }

  return {
    algoliaTopicsQuery,
    algoliaRatingCutOffQuery,
    algoliaAssessmentsQuery,
    algoliaRequiredTagsQuery,
    algoliaStateQuery,
  };
}

export function* getContentfulLocaleFilter() {
  const language = yield select(makeSelectLanguage());
  return {
    locale: language,
  };
}

export const isNpsSurvey = (
  showHomepagePro,
  queryParams,
  isClientAdmin,
  profile,
) =>
  !(
    showHomepagePro ||
    queryParams.showcorp === '1' ||
    isClientAdmin ||
    profile.role === 'admin' ||
    profile.role === 'contentAdmin'
  );

export const languageCodeMapping = {
  english: 'en-US',
  en: 'en-US',
  spanish: 'es',
};

export const reverseLanguageCodeMapping = {
  'en-US': 'en',
};

export const navigatorLanguageToContentfulMapping = {
  'zh-CN': 'zh-Hans',
  'zh-TW': 'zh-Hant',
};

export const setLanguageToLocalStorage = locale => {
  if (isLocalStorageAllowed()) {
    localStorage.setItem('language', languageCodeMapping[locale] || locale);
  }
};

export const getInitialLanguageCode = () => {
  if (!isLocalStorageAllowed()) {
    return '';
  }

  const languageCode = localStorage.getItem('language') || '';
  return languageCodeMapping[languageCode] || languageCode;
};

// const METADATA_OVERWRITE_FIELDS = [
//   'insuranceModal',
//   'linksTypeLabel',
//   'hideSubscription',
//   'authStackedLayout',
//   'showInsuranceType',
//   'displayNewLinkCard',
//   'isReverseLogoOrder',
//   'requirePersonalEmail',
//   'showWideResourceCard',
//   'hideSearchBar',
//   'showReferrals',
//   'hideNavBarSubMenu',
//   'includedTopicsTitle',
//   'showAtTheClinicButton',
//   'showClinicalViewButton',
//   'hideEmailMyResultsButton',
//   'hideTakeOtherAssessmentsButton',
//   'hideResourcePageRecommendations',
//   'listWeights',
//   'filterWeights',
//   'personalizeVariant',
//   'useAppEmbedStyle',
//   'hideLeftPanelActions',
//   'hidePdfDownloadButton',
// ];

const clean = object => {
  const cleanObject = { ...object };
  Object.keys(cleanObject).forEach(field => {
    if (_isNil(cleanObject[field])) {
      delete cleanObject[field];
    } else if (
      typeof cleanObject[field] === 'object' &&
      _has(cleanObject[field], 'json') &&
      documentToPlainTextString(cleanObject[field].json) === ''
    ) {
      delete cleanObject[field];
    } else if (
      typeof cleanObject[field] === 'object' &&
      _has(cleanObject[field], 'items') &&
      _isEmpty(cleanObject[field].items)
    ) {
      delete cleanObject[field];
    }
  });
  return cleanObject;
};

export const parseClientDetails = (client, language) => {
  const clientGroup = _get(client, 'clientGroup');
  const isPartOfClientGroup = !_isEmpty(clientGroup);

  let finalData = { ...client };
  if (isPartOfClientGroup) {
    const mergedExcludeResourceTypes = _uniq(
      (_get(clientGroup, 'excludeResourceTypes') || []).concat(
        _get(client, 'excludeResourceTypes') || [],
      ),
    );
    const mergedExcludeTopics = _uniqBy(
      (_get(clientGroup, 'excludeTopicCollection.items') || []).concat(
        _get(client, 'excludeTopicCollection.items') || [],
      ),
      'sys.id',
    );
    const mergedExcludeAssessment = _uniqBy(
      (_get(clientGroup, 'excludeAssessmentCollection.items') || []).concat(
        _get(client, 'excludeAssessmentCollection.items') || [],
      ),
      'sys.id',
    );
    const mergedClientResources = _uniqBy(
      (_get(clientGroup, 'clientResourcesCollection.items') || []).concat(
        _get(client, 'clientResourcesCollection.items') || [],
      ),
      'sys.id',
    );
    const parsedCustomFonts = !_isEmpty(
      _get(client, 'customFontsCollection.items', []),
    )
      ? _get(client, 'customFontsCollection.items', [])
      : _get(clientGroup, 'customFontsCollection.items', []);
    const parsedLanguage = !_isEmpty(
      _get(client, 'languageCollection.items', []),
    )
      ? _get(client, 'languageCollection.items', [])
      : _get(clientGroup, 'languageCollection.items', []);

    const clientGroupMetadata = _get(clientGroup, 'metadata') || {};
    // METADATA_OVERWRITE_FIELDS.forEach(field => {
    //   if (!_isNil(_get(clientGroup, ['metadata', field]))) {
    //     clientGroupMetadata[field] = _get(clientGroup, ['metadata', field]);
    //   }
    // });
    const finalMetadata = update(clientGroupMetadata, {
      $merge: _get(client, 'metadata') || {},
    });
    const trimmedClientGroup = clean(clientGroup);
    const trimmedClient = clean(client);

    finalData = {
      ...trimmedClientGroup,
      ...trimmedClient,
      customFontsCollection: {
        ..._get(client, 'customFontsCollection', {}),
        items: parsedCustomFonts,
      },
      clientResourcesCollection: {
        ..._get(client, 'clientResourcesCollection', {}),
        items: mergedClientResources,
      },
      excludeResourceTypes: mergedExcludeResourceTypes,
      excludeTopicCollection: {
        ..._get(client, 'excludeTopicCollection', {}),
        items: mergedExcludeTopics,
      },
      excludeAssessmentCollection: {
        ..._get(client, 'excludeAssessmentCollection', {}),
        items: mergedExcludeAssessment,
      },
      languageCollection: {
        ..._get(client, 'languageCollection', {}),
        items: parsedLanguage,
      },
      metadata: finalMetadata,
    };
  }

  const cleanClientResources = _get(
    finalData,
    'clientResourcesCollection.items',
    [],
  ).filter(item => !_isEmpty(item) && item.sys);
  const cleanExcludeAssessment = _get(
    finalData,
    'excludeAssessmentCollection.items',
    [],
  ).filter(item => !_isEmpty(item));
  const cleanExcludeTopic = _get(
    finalData,
    'excludeTopicCollection.items',
    [],
  ).filter(item => !_isEmpty(item));
  const cleanInCrisisResources = _get(
    finalData,
    'inCrisisResourcesCollection.items',
    [],
  ).filter(item => !_isEmpty(item));
  const context = {
    ..._get(finalData, 'context', {}),
  };
  if (!_isEmpty(_get(context, 'requiredEmailDomains'))) {
    const finalDomains = _get(context, 'requiredEmailDomains').map(domain => {
      if (typeof domain === 'string') return domain.toLowerCase();
      return domain;
    });

    context.requiredEmailDomains = _uniq(finalDomains);
  }

  const clientDetails = {
    ...finalData,
    clientResourcesCollection: {
      ..._get(finalData, 'clientResourcesCollection', {}),
      items: cleanClientResources,
    },
    excludeAssessmentCollection: {
      ..._get(finalData, 'excludeAssessmentCollection', {}),
      items: cleanExcludeAssessment,
    },
    excludeTopicCollection: {
      ..._get(finalData, 'excludeTopicCollection', {}),
      items: cleanExcludeTopic,
    },
    inCrisisResourcesCollection: {
      ..._get(finalData, 'inCrisisResourcesCollection', {}),
      items: cleanInCrisisResources,
    },
    language,
    context,
  };

  return clientDetails;
};

export const getAlgoliaClientQuery = (
  clientDetails,
  field = 'client.shortName',
  excludeField = 'clientExclude.shortName',
) => {
  if (_isEmpty(clientDetails)) return `${field}:"none"`;

  const hasClientGroup = !_isEmpty(_get(clientDetails, 'clientGroup'));
  if (hasClientGroup)
    return `(${field}:"none" OR ${field}:"${
      clientDetails.shortName
    }" OR ${field}:"${_get(
      clientDetails,
      'clientGroup.shortName',
    )}") AND NOT ${excludeField}:"${
      clientDetails.shortName
    }" AND NOT ${excludeField}:"${_get(
      clientDetails,
      'clientGroup.shortName',
    )}"`;

  return `(${field}:"none" OR ${field}:"${clientDetails.shortName}") AND NOT ${excludeField}:"${clientDetails.shortName}"`;
};

export const getAccentColor = (clientDetails, fallback = '01619B') => {
  if (_isEmpty(clientDetails)) return fallback;
  const accentColor = _get(
    clientDetails,
    'metadata.brandColors.accent',
    fallback,
  );

  if (accentColor && !/^#?(ffffff|fff)$/i.test(accentColor)) {
    return accentColor;
  }

  return fallback;
};

export const isReferenceAssessmentAccepted = ({ reference, clientDetails }) => {
  // Return early if there is no reference or clientDetails
  if (_isEmpty(reference)) return true;
  if (_isEmpty(clientDetails)) return true;

  const clientExcludedAssessments = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
  );
  const clientExcludedTopics = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );

  // Return early if Assessment is not of status Accepted
  if (!_get(reference, 'fields.reviewStatus') === 'Accepted') return false;

  // Check if client can show Assessment
  const clientId = _get(clientDetails, 'sys.id');
  const referenceClients = (_get(reference, 'fields.client') || []).map(
    client => _get(client, 'sys.id'),
  );
  if (!_isEmpty(referenceClients) && !referenceClients.includes(clientId)) {
    return false;
  }

  const referenceSlug = _get(reference, 'fields.slug');
  const isReferenceClientAssessmentExcluded = clientExcludedAssessments.some(
    ({ slug }) => slug === referenceSlug,
  );

  // Return early if Assessment is client excluded
  if (isReferenceClientAssessmentExcluded) return false;

  const referenceTopicSlugs = new Set(
    (_get(reference, 'fields.topics') || []).map(topic =>
      _get(topic, 'fields.slug'),
    ),
  );
  const isReferenceClientTopicExcluded = clientExcludedTopics.some(({ slug }) =>
    referenceTopicSlugs.has(slug),
  );

  // Return early if Assessment topic belongs to client excluded topics
  if (isReferenceClientTopicExcluded) return false;

  return true;
};
