import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { setLanguage } from 'containers/Main/actions';
import {
  makeSelectLocales,
  makeSelectClientDetails,
} from 'containers/Main/selectors';
import useContentfulLocale from 'components/useContentfulLocale';

import { isEmbedded } from '../../utils/embedded';

const withPageLanguage = Component => props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locales = useSelector(makeSelectLocales());
  const clientDetails = useSelector(makeSelectClientDetails());
  const clientGroupLanguages = _get(
    clientDetails,
    'clientGroup.languageCollection.items',
    [],
  );
  const clientLanguages = _get(clientDetails, 'languageCollection.items', []);
  const location = useLocation();
  const finalClientLanguages = !_isEmpty(clientGroupLanguages)
    ? clientGroupLanguages
    : clientLanguages;

  const ctfLocaleFilter = useContentfulLocale();
  const { search, pathname } = location;
  const searchObject = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const isEmbed = isEmbedded();
  useEffect(() => {
    if (
      !_isEmpty(locales) &&
      !_isEmpty(_get(searchObject, 'lang')) &&
      !_isEmpty(finalClientLanguages)
    ) {
      const { lang, ...params } = searchObject;

      const isLangAvailableInClient = finalClientLanguages.find(
        clientLang => _get(clientLang, 'shortcode') === lang,
      );

      if (ctfLocaleFilter.locale !== lang && isLangAvailableInClient) {
        const locale = locales.find(l => l.code === lang);
        const languageCode = locale ? locale.code : 'en-US';

        dispatch(setLanguage(languageCode));
      }

      if (!isEmbed) {
        history.replace(
          `${pathname}${!_isEmpty(params) ? `?${qs.stringify(params)}` : ''}`,
        );
      }
    }
  }, [dispatch, history, locales, searchObject, finalClientLanguages]);

  return <Component {...props} langObject={searchObject} />;
};

export default withPageLanguage;
