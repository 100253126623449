import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClassNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {
  showAbout,
  showLanguageBanner,
  setLanguage,
} from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectInsuranceModal,
  makeSelectIsFreshworksLoaded,
  makeSelectLanguage,
  makeSelectShowLanguageBanner,
} from 'containers/Main/selectors';
import getLanguageByCode from 'containers/Main/services/getLanguageByCode.gql';
import LandingMenuSelector from 'components/LandingMenuSelector';
import DownArrowIcon from 'components/LandingMenuSelector/down-arrow.svg';
import useSiteCopySelector from 'components/useSiteCopySelector';
import useWindowSize from 'components/useWindowSize';
import Mixpanel from 'utils/mixpanelService';
import { isLocalStorageAllowed, isIE } from 'utils/stringUtils';
import { isEmbedded } from 'utils/embedded';
import freshworksUtils from 'utils/freshworksUtils';
import useInCrisis from 'containers/InCrisis/useInCrisis';
import AtTheClinicButton from './AtTheClinicButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    height: 36,
    minHeight: 36,
    background: 'white',
    width: '100%',
    borderBottom: '1px solid #E3E3E3',
    [theme.breakpoints.down('900')]: {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('600')]: {
      position: 'inherit',
      height: 'auto',
    },
  },
  elevatedRoot: {
    zIndex: 1301,
  },
  stick: {
    top: 0,
    background: '#fff',
  },
  container: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('1140')]: {
      width: 1140,
      padding: 0,
    },
    width: '100%',
    padding: '0 5%',
    [theme.breakpoints.down('350')]: {
      padding: 0,
    },
  },
  rightMenuWrapper: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('1140')]: {
      right: '5%',
    },
  },
  rightMenuWrapperIE11: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    top: 0,
  },
  rightMenuButton: {
    ...theme.typography.overline,
    color: '#6C6C6C',
    padding: '0 5px',
    marginRight: 12,
    textTransform: 'uppercase',
    '&:last-child': {
      marginRight: 0,
    },
  },
  languageInfo: {
    display: 'flex',
    paddingRight: 20,
    ...theme.typography.overline,
    color: '#6C6C6C',
    '& :hover': {
      background: 'transparent',
    },
  },
  isEmbed: {
    paddingLeft: 14,
    paddingRight: 0,
    borderLeft: '1px solid #E0E0E0',
    marginLeft: 14,
  },
  img: {
    height: 12,
    marginRight: 7,
  },
  columnized: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: '16px',
  },
  popper: {
    marginTop: 15,
    zIndex: 1400,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
    },
  },
  paper: {
    border: '1px solid #E3E3E3',
    width: 111,
    boxShadow: '3px 2px 94px 0 rgba(0,0,0,0.09)',
    maxHeight: '60vh',
    overflowY: 'auto',
  },
  list: {
    padding: 0,
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&:after': {
      bottom: '100%',
      left: 0,
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(255, 255, 255, 0)',
      borderBottomColor: '#FFFFFF',
      borderWidth: 7,
      marginLeft: '-14px',
      top: '-6px',
    },
    '&:before': {
      bottom: '100%',
      left: 0,
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(227, 227, 227, 0)',
      borderBottomColor: '#E3E3E3',
      borderWidth: 8,
      marginLeft: '-15px',
      top: '-9px',
    },
  },
  button: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
  },
  text: {
    '& span': {
      ...theme.typography.overline,
      color: '#6C6C6C',
    },
  },
  textActive: {
    '& span': {
      color: theme.palette.primary.main,
      fontFamily: 'MadaBold',
    },
  },
  icon: {
    marginLeft: 10,
  },
}));

const LanguageBanner = ({
  hasMultipleLanguage,
  hideAbout,
  hideInCrisis,
  orientation = 'row',
}) => {
  const classes = useStyles();
  const language = useSelector(makeSelectLanguage());
  const showBanner = useSelector(makeSelectShowLanguageBanner());
  const insuranceModal = useSelector(makeSelectInsuranceModal());
  const clientDetails = useSelector(makeSelectClientDetails());
  const isFreshworksLoaded = useSelector(makeSelectIsFreshworksLoaded());
  const [footerSiteCopy] = useSiteCopySelector(['footer']);
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasBanner, setHasBanner] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const clientLanguages = _get(clientDetails, 'languageCollection.items', []);
  const hasMultipleLanding =
    _get(clientDetails, 'landingLinksCollection.items', [])
      .filter(item => !_isEmpty(item))
      .filter(({ showMenu }) => showMenu === 'yes').length > 1;
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowSize();
  const isMobile = width <= 600;
  const isTopicPage = location.pathname.includes('/topics/');
  const isCompanyPage = location.pathname.includes('/company/');
  const isEmbed = isEmbedded();
  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  const showAtTheClinicButton = _get(
    clientDetails,
    'metadata.showAtTheClinicButton',
    false,
  );
  const { showInCrisisInHeader, renderInCrisisButton } = useInCrisis({
    buttonClassName: classes.rightMenuButton,
  });

  const [hideAboutLink, setHideAboutLink] = useState(
    _get(clientDetails, 'metadata.hideAboutLink', false),
  );

  useEffect(() => {
    if (!_isEmpty(clientDetails))
      setHideAboutLink(_get(clientDetails, 'metadata.hideAboutLink', false));
  }, [clientDetails]);

  const { data, status, isFetching } = useQuery(
    language && ['language', language],
    getLanguageByCode,
  );
  const isSuccess = status === 'success';

  useEffect(() => {
    if (!isFetching) {
      if (!data) {
        if (isLocalStorageAllowed()) {
          // clear any localstorage
          localStorage.removeItem('language');
        }
        dispatch(showLanguageBanner(false));
      } else {
        dispatch(showLanguageBanner(hasMultipleLanguage));
      }
    }
    if (showBanner && !_isEmpty(data)) {
      setHasBanner(true);
    }
  }, [data, isFetching]);

  const shouldShowInCrisisInHeader = !hideInCrisis && showInCrisisInHeader;

  if (
    (!hasBanner && !hasMultipleLanding && !shouldShowInCrisisInHeader) ||
    isCompanyPage
  )
    return null;

  const shouldShowAbout = !hideAbout && !hideAboutLink;
  const shouldShowSupportButton = isFreshworksLoaded && !isMobile;

  const BtnContent = () => {
    if (!data) return null;
    const { menuName } = data;
    const url = _get(data, 'image.url');
    return (
      <ButtonBase
        className={ClassNames(classes.languageInfo, {
          [classes.isEmbed]: useAppEmbedStyle,
        })}
        aria-describedby={id}
        onClick={handleClick}
        disableRipple
      >
        <Box component="div" className="notranslate">
          {url && (
            <img
              src={url}
              alt={`${menuName} Language`}
              className={classes.img}
            />
          )}
          {menuName}
          <img src={DownArrowIcon} className={classes.icon} alt="" />
        </Box>
      </ButtonBase>
    );
  };

  const handleClick = event => {
    Mixpanel.track('Language Dropdown - Clicked');
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };
  const handleListItemClick = (_, shortcode) => {
    setAnchorEl(null);
    setSelectedLanguage(shortcode);
    dispatch(setLanguage(shortcode));
    Mixpanel.track('Language Item - Clicked', {
      language: shortcode,
    });
  };

  const handleAboutClick = () => dispatch(showAbout(true));

  const handleSupportClick = () => {
    freshworksUtils.show();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const languageSelector = () => (
    <>
      {isSuccess && BtnContent()}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        disablePortal
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: !isMobile,
            element: arrowRef,
          },
        }}
        className={classes.popper}
      >
        {() => (
          <>
            {!isMobile && <span className={classes.arrow} ref={setArrowRef} />}
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={classes.paper}>
                <List className={classes.list}>
                  {clientLanguages.map(({ shortcode, title }) => (
                    <ListItem
                      button
                      key={shortcode}
                      selected={selectedLanguage === 0}
                      onClick={event => handleListItemClick(event, shortcode)}
                    >
                      <ListItemText
                        primary={title}
                        className={ClassNames(
                          classes.text,
                          'notranslate',
                          selectedLanguage === title.toLowerCase()
                            ? classes.textActive
                            : undefined,
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </>
        )}
      </Popper>
    </>
  );

  if (useAppEmbedStyle) {
    return hasBanner ? languageSelector() : null;
  }

  return (
    <section
      className={ClassNames(classes.root, {
        [classes.stick]: isTopicPage,
        [classes.elevatedRoot]: insuranceModal.visible,
      })}
    >
      <div
        className={ClassNames(classes.container, {
          [classes.columnized]: orientation === 'column',
        })}
      >
        {hasBanner && languageSelector()}
        {hasMultipleLanding && <LandingMenuSelector />}
        <div
          className={ClassNames({
            [classes.rightMenuWrapper]: true,
            [classes.rightMenuWrapperIE11]: isIE(),
          })}
        >
          {showAtTheClinicButton ? (
            <AtTheClinicButton />
          ) : shouldShowAbout || shouldShowSupportButton ? (
            <>
              {shouldShowSupportButton && (
                <ButtonBase
                  onClick={handleSupportClick}
                  disableRipple
                  disableTouchRipple
                  className={classes.rightMenuButton}
                >
                  {_get(footerSiteCopy, 'pageCopy.supportLabel', 'Support')}
                </ButtonBase>
              )}
              {shouldShowAbout && (
                <ButtonBase
                  onClick={handleAboutClick}
                  disableRipple
                  disableTouchRipple
                  className={classes.rightMenuButton}
                >
                  {_get(footerSiteCopy, 'pageCopy.aboutLabel')}
                </ButtonBase>
              )}
            </>
          ) : null}
          {!hideInCrisis && renderInCrisisButton()}
        </div>
      </div>
    </section>
  );
};

export default React.memo(LanguageBanner);
