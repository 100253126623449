import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  findNextIncompleteResource,
  getInitialActiveIdx,
  getResourceType,
} from 'containers/Series/utils';
import { sha256 } from 'js-sha256';
import { generateQuickGuid, replacePlaceholders } from 'utils/stringUtils';
import Config from 'utils/getEnvConfig';
import {
  COURSE_SIDEBAR_TABS,
  COURSE_WORKSHEET_CUSTOM_QUESTION_KEY,
  SERIES_TYPES,
} from './constants';
import WelcomeIcon from './components/icons/WelcomeIcon';
import LearnIcon from './components/icons/LearnIcon';
import ApplyIcon from './components/icons/ApplyIcon';
import ReflectIcon from './components/icons/ReflectIcon';
import NextStepIcon from './components/icons/NextStepIcon';

const { CERTIFICATE_S3_URL } = Config;

const WELCOME_VIDEO_ID = 'welcome-video';

export const getActiveItemIdx = ({ id, completedIds, items }) => {
  const idx = completedIds.includes(id)
    ? findNextIncompleteResource(0, items, completedIds)
    : items.findIndex(item => item.sys.id === id);
  return idx === -1 ? 0 : idx;
};

export const updateLastViewedResourceInModule = ({
  course,
  moduleId,
  seriesId,
  resourceId,
  isResourceCompleted = false,
  completedResourcesIds,
  resourcesProgress,
}) => {
  const seriesProgress = course ? course.seriesProgress : {};
  const seriesCompletedResourcesIds =
    completedResourcesIds ||
    _get(seriesProgress, [seriesId, 'completedResourcesIds'], []);
  const seriesResourcesProgress = _get(seriesProgress, [
    seriesId,
    'resourcesProgress',
  ]);

  return {
    activeModuleId: moduleId,
    activeSeriesId: seriesId,
    seriesProgress: {
      ...seriesProgress,
      [seriesId]: {
        resourcesProgress: {
          ...seriesResourcesProgress,
          ...resourcesProgress,
        },
        completedResourcesIds: seriesCompletedResourcesIds,
        lastViewedResource: {
          id: resourceId,
          completed: isResourceCompleted,
        },
      },
    },
  };
};

export const getNextItemIdx = (items, idx, fallback = 0) =>
  idx + 1 === items.length ? fallback : idx + 1;

export const getInitialCourseState = (taken, courseModule) => {
  const seriesIdx = getActiveItemIdx({
    id: _get(taken, 'activeSeriesId'),
    completedIds: _get(taken, 'completedSeriesIds') || [],
    items: _get(courseModule, 'fields.moduleResources'),
  });
  const series = _get(courseModule, ['fields', 'moduleResources', seriesIdx]);
  const takenSeries = _get(taken, ['seriesProgress', series.sys.id], {});
  const seriesResources = _get(series, 'fields.resources', []);
  const resourceIdx = getInitialActiveIdx(seriesResources, takenSeries);
  return { seriesIdx, resourceIdx: resourceIdx || 0 };
};

export const isItemDisabled = (itemIndex, completedIndexes) => {
  if (itemIndex === 0) {
    return false;
  }

  const sortedIndexes = [...completedIndexes].sort();
  return sortedIndexes.length
    ? itemIndex > sortedIndexes[sortedIndexes.length - 1] + 1
    : true;
};

export const getCourseAnswers = (takenCourses, courseId, field) =>
  _get(takenCourses, [courseId, field]) || {};

export const getWorksheetProgress = (takenCourses, courseId, resourceId) =>
  _get(takenCourses, [
    courseId,
    COURSE_WORKSHEET_CUSTOM_QUESTION_KEY,
    resourceId,
  ]);

const isSeriesWithoutResources = series =>
  [SERIES_TYPES.REFLECTION, SERIES_TYPES.NEXT_STEPS].includes(
    _get(series, 'fields.type'),
  );

export const getActiveTab = (series, resource) => {
  if (resource) {
    return getActiveTabByResourceType(resource);
  }

  const type = _get(series, 'fields.type');
  if (type === SERIES_TYPES.WELCOME && _get(series, 'fields.assets')) {
    return COURSE_SIDEBAR_TABS.WELCOME_SERIES;
  }

  const mapping = {
    [SERIES_TYPES.REFLECTION]: COURSE_SIDEBAR_TABS.REFLECTION,
    [SERIES_TYPES.NEXT_STEPS]: COURSE_SIDEBAR_TABS.NEXT_STEPS,
  };

  return mapping[type] || COURSE_SIDEBAR_TABS.RESOURCE;
};

export const getActiveTabByResourceType = resource => {
  if (_get(resource, 'sys.id') === WELCOME_VIDEO_ID) {
    return COURSE_SIDEBAR_TABS.WELCOME_SERIES;
  }

  const type = getResourceType(resource);
  return type === 'worksheets'
    ? COURSE_SIDEBAR_TABS.WORKSHEET
    : COURSE_SIDEBAR_TABS.RESOURCE;
};

export const getSeriesIconByType = series => {
  const { type } = series.fields;

  return {
    [SERIES_TYPES.WELCOME]: WelcomeIcon,
    [SERIES_TYPES.LEARN]: LearnIcon,
    [SERIES_TYPES.APPLY]: ApplyIcon,
    [SERIES_TYPES.REFLECTION]: ReflectIcon,
    [SERIES_TYPES.NEXT_STEPS]: NextStepIcon,
  }[type];
};

export const getActiveCourseModuleIdx = (course, taken) =>
  getActiveItemIdx({
    id: _get(taken, 'activeModuleId'),
    completedIds: _get(taken, 'completedModulesIds') || [],
    items: _get(course, 'fields.modules'),
  });

export const getLastCompletedModuleIdx = taken =>
  _get(taken, 'completedModulesIds', []).length - 1;

export const createNewAnswers = ({
  currentAnswers,
  isUpdate,
  question,
  maxLength = 3,
}) => {
  const answerIdx = currentAnswers.findIndex(
    ans => ans.id === question.answerId,
  );

  let newAnswers;

  if (isUpdate) {
    const newAnswer = {
      ...currentAnswers[answerIdx],
      answer: question.value,
      updatedAt: Date.now(),
    };
    newAnswers = [
      ...currentAnswers.slice(0, answerIdx),
      newAnswer,
      ...currentAnswers.slice(answerIdx + 1, maxLength),
    ];
  } else {
    const newAnswer = {
      id: generateQuickGuid(),
      answer: question.value,
      createdAt: Date.now(),
      updatedAt: null,
    };
    newAnswers =
      currentAnswers.length === maxLength
        ? [newAnswer, ...currentAnswers.slice(1, maxLength)]
        : [newAnswer, ...currentAnswers.slice(0, maxLength)];
  }

  return newAnswers;
};

export const mapSeriesResource = resource => {
  if (_get(resource, 'sys.contentType.sys.id') !== 'assessments') {
    return { ...resource, id: resource.sys.id };
  }

  const { fields, ...rest } = resource;

  return {
    ...rest,
    fields: { ...fields, factoid: null, source: null },
  };
};

const getCourseSeries = course => {
  const modules = _get(course, ['fields', 'modules'], []);

  return modules.reduce((acc, module) => {
    const moduleResources = _get(module, ['fields', 'moduleResources'], []);
    acc.push(...moduleResources);

    return acc;
  }, []);
};

const getCourseReflectionSeries = courseSeries =>
  (courseSeries || []).filter(
    series => _get(series, 'fields.type') === SERIES_TYPES.REFLECTION,
  );

const getCourseResources = courseSeries =>
  courseSeries.reduce((acc, series) => {
    const resources = _get(series, ['fields', 'resources'], []);
    acc.push(...resources);

    return acc;
  }, []);

const getCourseTotalNumberOfResources = courseSeries => {
  const resources = getCourseResources(courseSeries);
  return resources.length;
};

const getCompletedNumberOfResources = (courseSeries, seriesProgress) => {
  if (_isEmpty(seriesProgress)) return 0;

  let total = 0;

  // For each resources in series
  courseSeries.forEach(series => {
    const resources = _get(series, ['fields', 'resources'], []);
    const seriesId = _get(series, 'sys.id');

    const seriesCompletedResourceIds = _get(
      seriesProgress,
      [seriesId, 'completedResourcesIds'],
      [],
    );
    // For each resource in series
    resources.forEach(resource => {
      if (seriesCompletedResourceIds.includes(_get(resource, 'sys.id'))) {
        total += 1;
      }
    });
  });

  return total;
};

const getCourseBasePathname = match =>
  match.url
    .split('/')
    .slice(0, 3)
    .join('/');

const getIntroductionPathname = (match, isEnrolled) => {
  const basePath = getCourseBasePathname(match);
  return isEnrolled ? `${basePath}/dashboard` : `${basePath}/introduction`;
};

const getModuleByMatchPath = (item, matchPath) => {
  const moduleSlug = matchPath.split('-module')[0];
  const foundIndex = item.fields.modules.findIndex(
    m => m.fields.slug === moduleSlug,
  );
  const moduleIdx = foundIndex === -1 ? 0 : foundIndex;

  return {
    moduleIdx,
    courseModule: _get(item, ['fields', 'modules', moduleIdx]),
  };
};

const getInitialPathname = ({ isEnrolled, taken, match, item }) => {
  const basePath = getCourseBasePathname(match);

  const matchPath = match.params.path || '';
  if (matchPath === 'introduction') {
    return `${basePath}/introduction`;
  }

  if (matchPath.endsWith('-module') && isEnrolled) {
    const lastCompletedModuleIdx = getLastCompletedModuleIdx(taken);
    const { moduleIdx, courseModule } = getModuleByMatchPath(item, matchPath);

    if (moduleIdx !== -1 && moduleIdx <= lastCompletedModuleIdx + 1) {
      return `${basePath}/${courseModule.fields.slug}-module`;
    }
  }

  return getIntroductionPathname(match, isEnrolled);
};

const shouldCompleteSeries = ({
  resource,
  resources,
  completedResourcesIds,
}) => {
  const completedResources = completedResourcesIds.filter(id =>
    resources.some(r => r.sys.id === id),
  );

  return (
    completedResources.length === resources.length ||
    (completedResources.length === resources.length - 1 &&
      !completedResourcesIds.includes(resource.sys.id))
  );
};

const showRecommendedTab = series =>
  !!_get(series.fields, 'recommendedResources') &&
  getActiveTab(series) === COURSE_SIDEBAR_TABS.RESOURCE;

// It should return the last uncompleted module index
const getUncompletedModuleIdx = ({ modules, completedModulesIds }) => {
  const uncompletedModuleIdx = modules.findIndex(
    module => !completedModulesIds.includes(module.sys.id),
  );

  return uncompletedModuleIdx;
};

// It should return the last uncompleted series index
const getUncompletedSeriesIdx = ({ module, completedSeriesIds }) => {
  const moduleSeries = _get(module, ['fields', 'moduleResources'], []);

  const uncompletedSeriesIdx = moduleSeries.findIndex(
    series => !completedSeriesIds.includes(series.sys.id),
  );

  return uncompletedSeriesIdx;
};

// It should return the last uncompleted resource index
const getUncompletedResourceIdx = ({ series, seriesProgress }) => {
  if (isSeriesWithoutResources(series)) {
    return 0;
  }

  const seriesResources = _get(series, ['fields', 'resources'], []);
  const activeSeriesProgress = _get(
    seriesProgress,
    [_get(series, 'sys.id')],
    [],
  );

  const seriesCompletedResourceIds = _get(
    activeSeriesProgress,
    ['completedResourcesIds'],
    [],
  );

  const uncompletedResourceIdx = seriesResources.findIndex(
    resource => !seriesCompletedResourceIds.includes(resource.sys.id),
  );

  return uncompletedResourceIdx;
};

const getUncompletedIndexes = ({
  modules,
  completedModulesIds,
  completedSeriesIds,
  seriesProgress,
}) => {
  // Module
  const uncompletedModuleIdx = getUncompletedModuleIdx({
    modules,
    completedModulesIds,
  });
  const uncompletedModule = _get(modules, [uncompletedModuleIdx]);

  // If there is no uncompleted module, that means the course is completed
  if (uncompletedModuleIdx === -1) {
    return {
      uncompletedModuleIdx,
      uncompletedSeriesIdx: -1,
      uncompletedResourceIdx: -1,
    };
  }

  // Series
  const uncompletedModuleSeries = _get(
    uncompletedModule,
    ['fields', 'moduleResources'],
    [],
  );
  const uncompletedSeriesIdx = getUncompletedSeriesIdx({
    module: uncompletedModule,
    completedSeriesIds,
  });
  const uncompletedSeries = _get(uncompletedModuleSeries, [
    uncompletedSeriesIdx,
  ]);

  const uncompletedResourceIdx = getUncompletedResourceIdx({
    series: uncompletedSeries,
    seriesProgress,
  });

  return {
    uncompletedModuleIdx,
    uncompletedSeriesIdx,
    uncompletedResourceIdx,
  };
};

const getImmediateNextIdx = (idx, length) => {
  const nextIdx = idx + 1;
  if (nextIdx > length - 1) {
    return null;
  }

  return nextIdx;
};

const getImmediateNextIndexes = ({
  modules,
  series,
  resources,
  moduleIdx,
  seriesIdx,
  resourceIdx,
}) => {
  const nextResourceIdx = getImmediateNextIdx(resourceIdx, resources.length);
  if (nextResourceIdx !== null) {
    return {
      resourceIdx: nextResourceIdx,
      seriesIdx,
      moduleIdx,
    };
  }

  const nextSeriesIdx = getImmediateNextIdx(seriesIdx, series.length);
  if (nextSeriesIdx !== null) {
    return {
      resourceIdx: 0,
      seriesIdx: nextSeriesIdx,
      moduleIdx,
    };
  }

  const nextModuleIdx = getImmediateNextIdx(moduleIdx, modules.length);
  if (nextModuleIdx !== null) {
    return {
      resourceIdx: 0,
      seriesIdx: 0,
      moduleIdx: nextModuleIdx,
    };
  }

  return {
    resourceIdx: 0,
    seriesIdx: 0,
    moduleIdx: 0,
  };
};

const getCourseMeta = ({ item, coursesMeta, activeModule, match }) => {
  const coursesMetaTitle =
    _get(coursesMeta, 'title') || _get(item, 'fields.title', '');
  let title;

  if (!match.params.path || match.params.path === 'introduction') {
    title = replacePlaceholders(coursesMetaTitle, {
      '#dashboard': '',
      '#module': '',
    });
  } else if (match.params.path === 'dashboard') {
    title = replacePlaceholders(coursesMetaTitle, {
      '#dashboard': 'Dashboard - ',
      '#module': '',
    });
  } else {
    title = replacePlaceholders(coursesMetaTitle, {
      '#dashboard': '',
      '#module': _get(activeModule, 'fields.title', ''),
    });
  }

  const pageMeta = {
    description: _get(coursesMeta, 'description'),
    title: title.trim(),
  };

  return pageMeta;
};

const createLinkAndDownload = (blob, fileName) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
    });
    a.removeEventListener('click', clickHandler);
  };

  a.addEventListener('click', clickHandler, false);
  a.click();
};

export const getCertificateLink = (userId, courseId, timestamp) => {
  const filename = `${sha256(`${userId}-${courseId}-${timestamp}`)}.pdf`;

  return `${CERTIFICATE_S3_URL}${filename}`;
};

const downloadPdfFile = async (pdf, headers) => {
  const res = await fetch(pdf.fields.file.url, { headers });
  const blob = await res.blob();

  createLinkAndDownload(blob, pdf.fields.file.fileName);
};

const hasModuleStarted = (courseModule, takenCourse) => {
  const moduleSeries = _get(courseModule, 'fields.moduleResources', []);
  const seriesProgress = _get(takenCourse, 'seriesProgress', {});
  return moduleSeries.every(series => !seriesProgress[series.sys.id]);
};

const mapSavedSeriesResources = (savedSeriesResourceIds, course) => {
  const data = [];

  if (!savedSeriesResourceIds.length) {
    return data;
  }

  savedSeriesResourceIds.forEach(resourceId => {
    let item;

    course.fields.modules.find((m, moduleIdx) =>
      _get(m.fields, 'moduleResources', []).find((s, seriesIdx) => {
        const index = _get(s.fields, 'resources', []).findIndex(
          r => r.sys.id === resourceId,
        );
        if (index !== -1) {
          item = {
            module: course.fields.modules[moduleIdx],
            moduleIdx,
            resource: s.fields.resources[index],
            resourceIdx: index,
            series: s,
            seriesIdx,
          };
        }

        return index !== -1;
      }),
    );

    if (item) {
      data.push(item);
    }
  });

  return data;
};

const splitRecommendedResources = resources => {
  const data = {
    assessments: [],
    book: [],
    rest: [],
  };

  resources.forEach(resource => {
    const contentType = _get(resource, 'sys.contentType.sys.id');
    const key = data[contentType] ? contentType : 'rest';
    data[key].push(resource);
  });

  return data;
};

const checkCourseCompletion = (modules, completedModulesIds) =>
  !_isEmpty(completedModulesIds) &&
  modules.every(m => completedModulesIds.includes(m.sys.id));

const formatCourseContentfulEntries = course => {
  const items = _get(course, 'items');
  if (_isEmpty(items)) return course;

  course.items[0].fields.modules.forEach(m =>
    _get(m.fields, 'moduleResources', []).forEach(series => {
      if (getActiveTab(series) === COURSE_SIDEBAR_TABS.WELCOME_SERIES) {
        const item = {
          fields: {
            title: _get(series.fields, 'title'),
            slug: WELCOME_VIDEO_ID,
          },
          sys: {
            id: WELCOME_VIDEO_ID,
            contentType: {
              sys: {
                id: 'video',
              },
            },
          },
        };

        // eslint-disable-next-line no-param-reassign
        series.fields.resources = [
          item,
          ..._get(series.fields, 'resources', []),
        ];
      }
    }),
  );

  return course;
};

export {
  getCourseSeries,
  getCourseReflectionSeries,
  getCompletedNumberOfResources,
  getCourseTotalNumberOfResources,
  getInitialPathname,
  showRecommendedTab,
  getIntroductionPathname,
  getModuleByMatchPath,
  shouldCompleteSeries,
  isSeriesWithoutResources,
  getImmediateNextIndexes,
  getUncompletedIndexes,
  getCourseMeta,
  downloadPdfFile,
  hasModuleStarted,
  mapSavedSeriesResources,
  splitRecommendedResources,
  checkCourseCompletion,
  formatCourseContentfulEntries,
};
