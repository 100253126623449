import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeSelectClientDetails } from 'containers/Main/selectors';

export const articleTypesInClientExcludedResources = new Set([
  'News',
  'Articles',
  'Insights',
]);
const articleTypesFromCTFAndAlgolia = new Set(['activity', 'article']);

export const isArticleTypesExcludedCheck = (
  items,
  clientExcludedResourceTypes,
) => {
  if (_isEmpty(clientExcludedResourceTypes) || _isEmpty(items)) return items;
  // eslint-disable-next-line no-param-reassign
  if (!Array.isArray(items)) items = [items];
  return items.filter(item => {
    const finalType = _get(item, 'sys.contentType.sys.id', 'fields.type');

    if (!articleTypesFromCTFAndAlgolia.has(finalType)) return true;

    const itemType = _get(item, 'fields.type');
    const isBlog = _get(item, 'fields.blog');
    if (isBlog) return !clientExcludedResourceTypes.has('Insights');
    if (itemType === 'News') return !clientExcludedResourceTypes.has('News');
    return !clientExcludedResourceTypes.has('Articles');
  });
};

const useClientExcludedResourceType = () => {
  const clientDetails = useSelector(makeSelectClientDetails());
  const clientExcludedResourceTypes = new Set(
    _get(clientDetails, 'excludeResourceTypes', []),
  );

  const hasExcludedArticleType = [...clientExcludedResourceTypes].some(type =>
    articleTypesInClientExcludedResources.has(type),
  );

  const isArticleTypesExcluded = items =>
    isArticleTypesExcludedCheck(items, clientExcludedResourceTypes);

  return { isArticleTypesExcluded, hasExcludedArticleType };
};

export default useClientExcludedResourceType;
