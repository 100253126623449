import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { Box, makeStyles, Typography } from '@material-ui/core';
import RichTextReactRenderer from 'components/RichTextReactRenderer';
import AppContainer from 'components/AppContainer';
import Mixpanel from 'utils/mixpanelService';
import { CONTENT_BLOCK_KEYS, CONTENT_BLOCK_THEME } from '../../utils';
import { AdminButton } from '../AdminButton';
import { ReferenceCard } from '../ReferenceCard';

const mapBgTheme = bgColor =>
  CONTENT_BLOCK_THEME[bgColor] ||
  CONTENT_BLOCK_THEME[CONTENT_BLOCK_KEYS.DEFAULT_WHITE];

const useStyles = makeStyles(theme => ({
  container: ({ backgroundColor, isAssessmentResultsPage, withReference }) => ({
    padding: withReference ? '30px 46px' : '65px',
    ...(isAssessmentResultsPage && {
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      },
    }),
    ...(withReference && {
      [theme.breakpoints.down('sm')]: {
        padding: 'calc(5%) 0',
      },
      display: 'flex',
      alignItems: 'center',
    }),
    backgroundColor,
    position: 'relative',
  }),
  header: ({ color }) => ({
    textTransform: 'capitalize',
    color,
    marginBottom: 24,
  }),
  bodyContent: ({ color }) => ({
    color,
  }),
  content: {
    width: '100%',
    display: 'flex',
    gap: '60px',
    [theme.breakpoints.down('960')]: {
      flexDirection: 'column',
      gap: '30px',
    },
  },
  adminButtonPos: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  description: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  reference: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
}));

const BasicContentBlock = ({
  data,
  isAssessmentResultsPage = false,
  isAdmin,
  isPreview,
}) => {
  const {
    backgroundColor: bgColor,
    bodyContent,
    header,
    configurations,
    pagePosition,
    reference,
  } = data;

  let { backgroundColor, color, linkColor } = mapBgTheme(bgColor);
  if (configurations) {
    backgroundColor = configurations.backgroundColor || backgroundColor;
    color = configurations.color || color;
    linkColor = configurations.linkColor || linkColor;
  }
  const withReference = !_isEmpty(reference);

  const classes = useStyles({
    backgroundColor,
    color,
    isAssessmentResultsPage,
    withReference,
  });

  const handleCMLinkClick = () => {
    Mixpanel.track('Resource Card - Clicked', {
      path: `Text Block - ${pagePosition || ''}`,
    });
  };

  const content = (
    <Box className={classes.content}>
      {isAdmin && !isPreview && (
        <Box className={classes.adminButtonPos}>
          <AdminButton data={data} />
        </Box>
      )}
      <Box className={classes.description}>
        <Typography variant="h5" className={classes.header}>
          {header}
        </Typography>
        <Box className={classes.bodyContent}>
          <RichTextReactRenderer
            data={bodyContent}
            color={color}
            linkColor={linkColor}
            onCMLinkClick={handleCMLinkClick}
          />
        </Box>
      </Box>
      {withReference && (
        <Box className={classes.reference}>
          <ReferenceCard reference={reference} />
        </Box>
      )}
    </Box>
  );

  if (isAssessmentResultsPage)
    return <Box className={classes.container}>{content}</Box>;

  return (
    <Box className={classes.container}>
      <AppContainer customPadding>{content}</AppContainer>
    </Box>
  );
};

export default BasicContentBlock;
