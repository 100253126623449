import React from 'react';
import PropTypes from 'prop-types';
import { isEmbedded } from 'utils/embedded';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
  },
});

function Root({ children }) {
  const classes = useStyles();
  const isEmbed = isEmbedded();
  return <div className={!isEmbed ? classes.root : ''}>{children}</div>;
}

Root.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Root;
