import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import {
  getClientExcludedResourceTypesMapping,
  getFormattedType,
} from 'components/ResourceItemSelector/utils';
import { makeSelectClientDetails } from 'containers/Main/selectors';

const useClientExcludedResourceType = () => {
  const clientDetails = useSelector(makeSelectClientDetails());
  const clientExcludedResourceTypes =
    _get(clientDetails, 'excludeResourceTypes') || [];

  const mapResourceTypes = mapFunc =>
    clientExcludedResourceTypes.map(mapFunc).filter(Boolean);

  const clientExcludedResourceTypesToken = mapResourceTypes(
    getClientExcludedResourceTypesMapping,
  );
  const clientExcludedResourceTypesFromAlgoliaToken = mapResourceTypes(
    getFormattedType,
  );

  const isResourceTypeExcluded = resourceType =>
    clientExcludedResourceTypesToken.includes(resourceType);
  const isAlgoliaResourceTypeExcluded = resourceType =>
    clientExcludedResourceTypesFromAlgoliaToken.includes(resourceType);

  return {
    isResourceTypeExcluded,
    isAlgoliaResourceTypeExcluded,
    clientExcludedResourceTypes,
  };
};

export default useClientExcludedResourceType;
