import injectSaga from 'utils/injectSaga';
// common saga here
import AuthSaga from 'containers/Auth/saga';
import AccountSaga from 'containers/Account/saga';
import AssessmentsSaga from 'containers/Assessments/saga';
import TopicsSaga from 'containers/Topics/saga';
import AssessmentSaga from 'containers/Assessment/saga';
import SourcesSaga from 'containers/Sources/saga';
import ResourcesSaga from 'containers/Resources/saga';
import ClientLandingSaga from 'containers/ClientLanding/saga';
import HomePageSaga from 'containers/HomePage/saga';
import FavoritesSaga from 'containers/Favorites/saga';
import SeriesSaga from 'containers/Series/saga';
import CoursesSaga from 'containers/Course/saga';
import MainSaga from './saga';

const authSaga = injectSaga({ key: 'auth', saga: AuthSaga });
const accountSaga = injectSaga({ key: 'account', saga: AccountSaga });
const homePageSaga = injectSaga({ key: 'homepage', saga: HomePageSaga });
const assessmentsSaga = injectSaga({
  key: 'assessments',
  saga: AssessmentsSaga,
});
const topicsSaga = injectSaga({ key: 'topics', saga: TopicsSaga });
const assessmentSaga = injectSaga({ key: 'assesment', saga: AssessmentSaga });
const sourcesSaga = injectSaga({ key: 'sources', saga: SourcesSaga });
const resourcesSaga = injectSaga({ key: 'resources', saga: ResourcesSaga });
const mainSaga = injectSaga({ key: 'main', saga: MainSaga });
const clientLandingSaga = injectSaga({
  key: 'clientLanding',
  saga: ClientLandingSaga,
});
const favoritesSaga = injectSaga({
  key: 'favorites',
  saga: FavoritesSaga,
});
const seriesSaga = injectSaga({
  key: 'series',
  saga: SeriesSaga,
});
const coursesSaga = injectSaga({
  key: 'courses',
  saga: CoursesSaga,
});
export default {
  mainSaga,
  authSaga,
  accountSaga,
  assessmentsSaga,
  topicsSaga,
  assessmentSaga,
  sourcesSaga,
  resourcesSaga,
  clientLandingSaga,
  homePageSaga,
  favoritesSaga,
  seriesSaga,
  coursesSaga,
};
