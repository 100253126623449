import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import Mixpanel from 'utils/mixpanelService';
import FavoritesItem from './AccountSidebarItem';
import NavBarContext from '../../navBarContext';

const useStyles = makeStyles({
  root: {
    paddingTop: 25,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    color: props => props.theme.headerColor,
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '1.5',
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  callToAction: {
    fontFamily: 'Mada',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15.6px',
    color: props => props.theme.headerColor,
    textAlign: 'center',
  },
  viewAllWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    gap: 15,
  },
  viewAll: {
    textDecoration: 'underline',
    fontFamily: 'Mada',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15.6px',
    color: props => props.theme.linkColor,
  },
});

const FavoritesSection = ({ favoriteResources, theme, onHide }) => {
  const classes = useStyles({ theme });
  const locale = useAlgoliaLocale();
  const { clientDetails, siteCopy } = useContext(NavBarContext);
  const [siteConfig] = useSiteConfigSelector(['Profile Sidebar']);

  const handleFavorites = () => {
    onHide();
    Mixpanel.track('Profile - View All - Clicked', {
      section: 'favorites',
    });
  };
  const handleResources = () => {
    onHide();
    Mixpanel.track('Profile - View All - Clicked', {
      section: 'resources',
    });
  };
  const handleHistory = () => {
    onHide();
    Mixpanel.track('Profile - View All - Clicked', {
      section: 'history',
    });
  };

  const excludeResourceTypes =
    _get(clientDetails, 'excludeResourceTypes') || [];
  const sortedResources = favoriteResources
    .filter(item => !excludeResourceTypes.includes(item.type))
    .sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0));
  const hasFavorites = sortedResources.length > 0;

  return (
    <Box className={classes.root}>
      <Box className={classes.headerWrapper}>
        <Typography className={classes.header}>
          {_get(siteCopy, ['pageCopy', 'profile-sidebar', 'favoritesHeader'])}
        </Typography>
      </Box>
      <Box className={classes.itemsWrapper}>
        {hasFavorites ? (
          sortedResources
            .slice(0, _get(siteConfig, 'config.favorites.displayCount', 3))
            .map(item => {
              const path =
                item.expandedType === 'Online Programs'
                  ? 'programs'
                  : item.expandedType === 'FAQ'
                  ? 'faqs'
                  : item.expandedType === 'News'
                  ? 'articles'
                  : item.expandedType.toLowerCase();

              return (
                <FavoritesItem
                  key={item.slug}
                  theme={theme}
                  type="favorite"
                  resourceType={
                    item.expandedType === 'Insights'
                      ? 'Blogs'
                      : item.expandedType
                  }
                  title={
                    getAlgoliaLocalizedField(item, 'name', locale) ||
                    getAlgoliaLocalizedField(item, 'title', locale) ||
                    getAlgoliaLocalizedField(item, 'article', locale)
                  }
                  url={`/${path}/${item.slug}`}
                  onClick={() => {
                    onHide();
                    Mixpanel.track('Resource Card - Clicked', {
                      type: item.expandedType,
                      section: 'favorites',
                      slug: item.slug,
                      name:
                        getAlgoliaLocalizedField(item, 'name', locale) ||
                        getAlgoliaLocalizedField(item, 'title', locale) ||
                        getAlgoliaLocalizedField(item, 'article', locale),
                    });
                  }}
                />
              );
            })
        ) : (
          <Typography className={classes.callToAction}>
            {_get(siteCopy, ['pageCopy', 'profile-sidebar', 'favoritesCTA'])}
          </Typography>
        )}
      </Box>
      <Box className={classes.viewAllWrapper}>
        {hasFavorites ? (
          <>
            <Link to="/favorites" onClick={handleFavorites} disableRipple>
              <Typography className={classes.viewAll}>
                {_get(siteCopy, [
                  'pageCopy',
                  'profile-sidebar',
                  'favoritesViewAll',
                ])}
              </Typography>
            </Link>
            <Link to="/account/history" onClick={handleHistory} disableRipple>
              <Typography className={classes.viewAll}>
                {_get(siteCopy, [
                  'pageCopy',
                  'profile-sidebar',
                  'favoritesViewHistory',
                ])}
              </Typography>
            </Link>
          </>
        ) : (
          <>
            <Link to="/resources" onClick={handleResources} disableRipple>
              <Typography className={classes.viewAll}>
                {_get(siteCopy, [
                  'pageCopy',
                  'profile-sidebar',
                  'favoritesViewResources',
                ])}
              </Typography>
            </Link>
            <Link to="/account/history" onClick={handleHistory} disableRipple>
              <Typography className={classes.viewAll}>
                {_get(siteCopy, [
                  'pageCopy',
                  'profile-sidebar',
                  'favoritesViewHistory',
                ])}
              </Typography>
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
};

FavoritesSection.propTypes = {
  onHide: PropTypes.func,
  theme: PropTypes.object,
  favoriteResources: PropTypes.array,
};

export default FavoritesSection;
