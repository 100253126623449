import { getAlgoliaRatingQuery } from 'containers/HomePage/services/api-helper';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import { getAlgoliaFilters } from 'containers/Main/utils';
import Config from 'utils/getEnvConfig';

const {
  ALGOLIA: { SEARCH_INDEX },
} = Config;

export const addSectionToSuggestions = (
  suggestions,
  hits,
  sectionTitle,
  suggestionsType,
) => {
  if (hits.length > 0) {
    return suggestions.concat({
      title: sectionTitle,
      suggestions: hits.map(({ nameTitle, url, type, subtype }) => ({
        title: nameTitle,
        url,
        type: suggestionsType || type,
        subtype,
        blog: type === 'Blogs',
      })),
    });
  }
  return suggestions;
};

export const getQueryObject = (indexName, query, filters) => ({
  indexName,
  query,
  params: {
    hitsPerPage: 3,
    filters,
  },
});

export const getFiltersString = (clientDetails, config, locale) => {
  const prefix = locale !== 'en-US' ? `${locale}_` : '';

  const shouldFilterShortArticles = _get(
    clientDetails,
    'metadata.filterShortArticles',
    false,
  );
  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  const excludedAssessments = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
  );
  const { algoliaAssessmentsQuery = '' } = getAlgoliaFilters({
    excludedAssessments,
  });

  const excludedTopicSlug = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => topic.slug)
    : [];

  const clientQuery = !_isEmpty(_get(clientDetails, 'clientGroup'))
    ? `(clients:"none" OR clients:"${
        clientDetails.shortName
      }" OR clients:"${_get(
        clientDetails,
        'clientGroup.shortName',
      )}") AND NOT clientsExclude:"${
        clientDetails.shortName
      }" AND NOT clientsExclude:"${_get(
        clientDetails,
        'clientGroup.shortName',
      )}"`
    : `(clients:"none" OR clients:"${clientDetails.shortName}") AND NOT clientsExclude:"${clientDetails.shortName}"`;
  let filtersString = clientQuery;

  if (!_isEmpty(excludedTopicSlug)) {
    _forEach(excludedTopicSlug, slug => {
      filtersString += ` AND NOT slug:'${slug}' AND NOT filterTopics:'${slug}'`;
    });
  }

  if (algoliaAssessmentsQuery) {
    filtersString += algoliaAssessmentsQuery;
  }

  if (
    !_isEmpty(_get(clientDetails, 'metadata.hideCostDescriptionResources', []))
  ) {
    filtersString += ` AND ${_get(
      clientDetails,
      'metadata.hideCostDescriptionResources',
      [],
    )
      .map(item => `NOT ${prefix}costDescription:'${item}'`)
      .join(' AND ')}`;
  }

  const averageRatingCutoff = _get(clientDetails, 'averageRatingCutoff');
  const expertRatingCutoff = _get(clientDetails, 'expertRatingCutoff');
  const userRatingCutoff = _get(clientDetails, 'userRatingCutoff');

  const ratingFilter = getAlgoliaRatingQuery({
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
  });
  if (ratingFilter) {
    filtersString += ratingFilter;
  }

  if (shouldFilterShortArticles) {
    filtersString += ` AND wordCount > ${_get(
      config,
      'config.articlesMinimumWordCount',
      400,
    )}`;
  }

  return filtersString;
};

const SEARCH_FIELDS = [
  'nameTitle',
  'relatedTopics',
  'type',
  'aliases',
  'keywords',
  'personOrganization.name',
];

const SERIES_SEARCH_FIELDS = ['nameTitle', 'description'];

export const buildSearchAlgoliaQueries = ({
  filters,
  optionalFilters,
  minProximity,
  query,
  excludeResourceTypes,
  audienceFilters,
  requiredTagsFilters,
  excludedTagsFilters,
  stateFilters,
}) => {
  const restrictSearchableAttributes = SEARCH_FIELDS;
  const seriesRestrictSearchableAttributes = SERIES_SEARCH_FIELDS;
  const excludeResourceTypesFilters = _isEmpty(excludeResourceTypes)
    ? ''
    : ` AND ${excludeResourceTypes
        .map(type => `NOT type:'${type}'`)
        .join(' AND ')}`;

  return [
    {
      indexName: SEARCH_INDEX,
      query,
      params: {
        hitsPerPage: 5,
        filters: `${filters} AND type:'Topics'`,
        optionalFilters,
        minProximity,
        restrictSearchableAttributes,
      },
    },
    {
      indexName: SEARCH_INDEX,
      query,
      params: {
        hitsPerPage: 3,
        filters: `${filters} AND type:'Assessments'${requiredTagsFilters}${audienceFilters}`,
        optionalFilters,
        minProximity,
        restrictSearchableAttributes,
      },
    },
    {
      indexName: SEARCH_INDEX,
      query,
      params: {
        hitsPerPage: 3,
        filters: `${filters} AND type:'Insights'${audienceFilters}`,
        optionalFilters,
        minProximity,
        restrictSearchableAttributes,
      },
    },
    {
      indexName: SEARCH_INDEX,
      query,
      params: {
        hitsPerPage: 18,
        filters: `${filters} AND NOT type:'Topics' AND NOT type:'Assessments' AND NOT type:'Insights' AND NOT type:'Series'${excludeResourceTypesFilters} AND NOT displayOnly:true${stateFilters}${requiredTagsFilters}${excludedTagsFilters}${audienceFilters}`,
        optionalFilters,
        minProximity,
        restrictSearchableAttributes,
      },
    },
    !excludeResourceTypes.includes('Series') && {
      indexName: SEARCH_INDEX,
      query,
      params: {
        hitsPerPage: 3,
        filters: "type:'Series'",
        optionalFilters,
        minProximity,
        restrictSearchableAttributes: seriesRestrictSearchableAttributes,
      },
    },
  ].filter(Boolean);
};

export const getExclusiveBadge = (resource, clientDetails) => {
  const dataClients = _get(resource, 'clients', []);
  const resourceType = _get(resource, 'type');

  const isClientExclusive = dataClients.includes(
    _get(clientDetails, 'shortName'),
  );
  const isClientGroupExclusive = dataClients.includes(
    _get(clientDetails, 'clientGroup.shortName'),
  );

  const showClientBadge =
    isClientExclusive ||
    isClientGroupExclusive ||
    resourceType === 'Client Exclusive';

  const hasCustomBadge =
    _has(clientDetails, 'metadata.clientExclusiveIcon') && isClientExclusive;
  const hasAssetLogo =
    _has(clientDetails, 'logosCollection.items[0].url') && isClientExclusive;
  const isReverseLogoOrder = _get(clientDetails, 'metadata.isReverseLogoOrder');

  const badge = hasCustomBadge
    ? _get(clientDetails, 'metadata.clientExclusiveIcon')
    : hasAssetLogo
    ? _get(clientDetails, 'logosCollection.items[0].url')
    : isReverseLogoOrder
    ? _get(clientDetails, 'crediblemindLogo.url')
    : _get(clientDetails, 'logoReverse.url');

  const badgeTitle = isClientExclusive
    ? _get(clientDetails, 'metadata.clientExclusiveResource.tooltipBadge', '')
    : _get(
        clientDetails,
        'clientGroup.metadata.clientExclusiveResource.tooltipBadge',
        '',
      );

  return {
    hasCustomBadge,
    showClientBadge,
    badge,
    badgeTitle,
  };
};

export const addInternalTyping = (resource, internalType) => ({
  ...resource,
  internalType,
});

export const sortSearchPreviewResources = (resources, previewOrder) => {
  const groupResources = resources.reduce((result, item) => {
    const { internalType, ...rest } = item;
    const updatedResult = { ...result };

    if (!updatedResult[internalType])
      updatedResult[internalType] = {
        type: internalType,
        order: previewOrder[internalType] || 0,
        content: [],
      };

    updatedResult[internalType].content.push(rest);

    return updatedResult;
  }, {});

  const resourcesTypes = Object.keys(groupResources);

  Object.entries(previewOrder)
    .sort(([, orderA], [, orderB]) => orderB - orderA)
    .forEach(([itemToMove]) => {
      let indexToMove = previewOrder[itemToMove] - 1;

      if (indexToMove !== undefined) {
        if (indexToMove >= resourcesTypes.length || indexToMove < 0) {
          indexToMove = resourcesTypes.length - 1; // Set index to last if beyond array length
        }

        const itemIndex = resourcesTypes.indexOf(itemToMove);
        if (itemIndex !== -1) {
          const [removedItem] = resourcesTypes.splice(itemIndex, 1);
          resourcesTypes.splice(indexToMove, 0, removedItem);
        }
      }
    });

  const sortedResources = resourcesTypes.flatMap(
    type => groupResources[type].content,
  );

  return sortedResources;
};
