import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { getLocalData } from 'utils/localDataStore';
import { isLocalhost } from 'utils/networkUtils';
import { getLowerCaseEmailDomain, getSubdomain } from 'utils/stringUtils';
import { isEmbedded } from 'utils/embedded';

const ADMIN = 'admin';
const CONTENT_ADMIN = 'contentAdmin';

export const shouldShowContentfulTools = profile =>
  (profile.role === 'admin' || profile.role === 'contentAdmin') &&
  profile.showContentfulTools === true &&
  !isEmbedded();

export const hasAdminAccess = (profile, clientDetails) => {
  const brand = getLocalData('brand') || 'none';
  if (isEmbedded()) return false;
  return (
    (getIsClientAdmin(profile, clientDetails) ||
      [ADMIN, CONTENT_ADMIN].includes(_get(profile, 'role'))) &&
    brand !== 'none'
  );
};

export const isEqualProfileFilters = (current, incoming) => {
  const currentKeys = Object.keys(current)
    .sort()
    .join(',');
  const currentIsLoaded = _get(current, 'isLoaded');
  const currentAudiences = _get(current, 'audiences', [])
    .map(item => item.id)
    .sort()
    .join(',');
  const currentTags = _get(current, 'tags', [])
    .map(item => item.id)
    .sort()
    .join(',');
  const currentInsuranceTags = _get(current, 'insuranceTags', [])
    .map(item => item.id)
    .sort()
    .join(',');

  const incomingKeys = Object.keys(incoming)
    .sort()
    .join(',');
  const incomingIsLoaded = _get(current, 'isLoaded');
  const incomingAudiences = _get(incoming, 'audiences', [])
    .map(item => item.id)
    .sort()
    .join(',');
  const incomingTags = _get(incoming, 'tags', [])
    .map(item => item.id)
    .sort()
    .join(',');
  const incomingInsuranceTags = _get(incoming, 'insuranceTags', [])
    .map(item => item.id)
    .sort()
    .join(',');

  return (
    currentKeys === incomingKeys &&
    currentIsLoaded === incomingIsLoaded &&
    currentAudiences === incomingAudiences &&
    currentTags === incomingTags &&
    currentInsuranceTags === incomingInsuranceTags
  );
};

export const getIsClientAdmin = (profile, clientDetails, brand) => {
  const subdomain = getSubdomain();
  const localDataBrand = getLocalData('brand') || 'none';
  const finalSubdomain =
    brand || (isLocalhost || subdomain === null ? localDataBrand : subdomain); // subdomain is only null for special URLs

  const clientGroup = _get(clientDetails, 'clientGroup.shortName');

  return (
    _get(profile, 'isClientAdmin', false) &&
    (_get(profile, 'adminInClients', []).includes(finalSubdomain) ||
      (!_isEmpty(clientGroup) &&
        _get(profile, 'adminInClients', []).includes(clientGroup)))
  );
};

export const getUserDomainAllowed = ({
  profile,
  response,
  isBackdoor,
  clientDetails,
}) => {
  const requiredEmailDomains = _get(
    clientDetails,
    'context.requiredEmailDomains',
    [],
  );
  const shouldLimitEmailDomains = !_isEmpty(requiredEmailDomains);
  const isCurrentDomainAllowed = requiredEmailDomains.includes(
    getLowerCaseEmailDomain(
      _get(profile, 'email') || _get(response, 'email') || '',
    ),
  );
  const isRegistrationDomainAllowed =
    isBackdoor &&
    requiredEmailDomains.includes(
      getLowerCaseEmailDomain(_get(profile, 'providerData.email') || ''),
    );
  const isAdminOrClientAdmin =
    isBackdoor &&
    (_get(profile, 'role') === 'admin' ||
      getIsClientAdmin(profile, clientDetails));
  const isUserDomainAllowed =
    !shouldLimitEmailDomains ||
    isCurrentDomainAllowed ||
    isRegistrationDomainAllowed ||
    isAdminOrClientAdmin;

  return isUserDomainAllowed;
};
