import { gql } from '@apollo/client';
import {
  ActivityFragment,
  LandingFragment,
  LinkFragment,
  SourceFragment,
  ServiceFragment,
  FontsFragment,
} from './fragments';
import {
  PodcastFragment,
  VideoFragment,
  AppsFragment,
} from '../../ClientLanding/services/fragments';

export const DUPLICATED_FIELDS = `
  landingLinksCollection {
    items {
      title
      slug
      menuTitle
      menuImage {
        url
      }
      showMenu
      menuOrder
      sys {
        id
      }
      tagsCollection(limit: 10) {
        items {
          sys {
            id
          }
        }
      }
    }
  }
  landingMenuCollection {
    items {
      ...LandingFields
    }
  }
  colors
  signIn
  loginMethods,  
  samlConfiguration
  excludeResourceTypes
  excludeTopicCollection(limit:1000) {
    items {
      title
      slug
      reviewStatus
      type
      description
      sys {
        id
      }
      image {
        url
      }
    }
  }
  excludeAssessmentCollection {
    items {
      title
      name
      slug
      sys {
        id
      }
    }
  }
  userRatingCutoff
  expertRatingCutoff
  averageRatingCutoff
  footer {
    json
  }
  assessmentInCrisis {
    json
  }
  customFontsCollection {
    items {
      ...FontsFields
    }
  }
  aicc
  languageDisclaimer {
    json
  }
  
  metadata
  logoReverse {
    url
  }
  clientResourcesCollection {
    items {
      ...ClientSourceFields
      ...ClientActivityFields
      ...ClientServiceFields
      ...ClientLinkFields
      ...VideoFields
      ...PodcastFields
      ...ApplicationFields
    }
  }
  languageCollection {
    items {
      slug
      title
      shortcode
      rtlLanguage
      image {
        url
      }
    }
  }
`;

export default gql`
  ${SourceFragment}
  ${ActivityFragment}
  ${LinkFragment}
  ${LandingFragment}
  ${ServiceFragment}
  ${FontsFragment}
  ${VideoFragment}
  ${PodcastFragment}
  ${AppsFragment}
  query Client($shortName: String!, $locale: String!) {
    clientCollection(
      where: { shortName: $shortName }
      limit: 1
      locale: $locale
    ) {
      items {
        activeClient
        sys {
          id
        }
        clientGroup {
          sys {
            id
          }        
        }
        name
        slug
        url
        sector
        shortName
        formalShortName
        crediblemindLogo {
          url
        }
        inCrisisResourcesCollection {
          items {
            ...ClientSourceFields
            ...ClientActivityFields
            ...ClientLinkFields
          }
        }
        context
        clientConfigured
        contact {
          name
          role
          email
          phone
          image {
            url
          }
          calendar
        }
        report
        formalShortName
        locationCollection {
          items {
            location {
              lat
              lon
            }
            city
            state
          }
        }
        launchDate
        logosCollection {
          items {
            url
          }
        }
        reportsCollection {
          items {
            name
            sigmaEmbedId
            workbookId
            enabled
            options
            filterType
          }
        }
        audience {
          sys {
            id
          }
          name
        }
        ${DUPLICATED_FIELDS}
      }
    }
  }
`;
