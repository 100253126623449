import { createBrowserHistory } from 'history';
import _get from 'lodash/get';

const customHistory = createBrowserHistory();

const originalPush = customHistory.push;
const originalReplace = customHistory.replace;

// Check if embedded redirect value is new tab or not
export const isNotNewTabEmbeddedRedirect = () =>
  _get(window, 'xprops.redirect', '') !== 'new_tab';

export const isAssessmentPageTransition = pathname =>
  !pathname.includes('page') && pathname.includes('/assessments/');

// New function to determine if transition is from /assessments/any to /assessments/any/page1
export const isTransitionToPage = (currentPath, newPath) => {
  const basePathRegex = /^\/assessments\/[^/]+(?=\/|$)/;
  const currentPageMatch = currentPath.match(basePathRegex);
  const pathname =
    typeof newPath === 'string' ? newPath : _get(newPath, 'pathname', '');
  const newPageMatch = pathname.match(basePathRegex);

  // Check if both paths are assessment paths and newPath is a continuation of the currentPath
  return (
    currentPageMatch &&
    newPageMatch &&
    currentPath !== newPath &&
    newPath.startsWith(currentPath)
  );
};

customHistory.push = (path = '', state) => {
  if (isNotNewTabEmbeddedRedirect()) {
    if (isTransitionToPage(window.location.pathname, path)) {
      originalReplace.call(customHistory, path, state);
    } else {
      originalPush.call(customHistory, path, state);
    }
  } else {
    window.open(path, '_blank');
  }
};

customHistory.replace = (path, state) => {
  if (isNotNewTabEmbeddedRedirect()) {
    originalReplace.call(customHistory, path, state);
  }
};

export default customHistory;
