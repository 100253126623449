import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { getLocalData } from 'utils/localDataStore';
import { getCustomOnboardingLocalUtils } from '../CustomOnboardingDialog/utils';

const useIsCustomOnboardingEnabled = () => {
  const [customOnboardingSiteCopy] = useSiteCopySelector([
    'custom-onboarding-mapping',
  ]);
  const location = useLocation();
  const brand = getLocalData('brand');

  const config = _get(customOnboardingSiteCopy, 'pageCopy');
  const includedClients = _get(config, 'includedClients', []);
  const currentConfig = _get(
    config,
    ['clientsConfig', brand],
    _get(config, 'default', {}),
  );

  const isHomePage = location.pathname === '/';

  const { get } = getCustomOnboardingLocalUtils();
  const isCustomOnboardingViewed = !_isEmpty(get());

  return (
    isHomePage &&
    includedClients.includes(brand) &&
    !_isEmpty(currentConfig) &&
    !isCustomOnboardingViewed
  );
};

export default useIsCustomOnboardingEnabled;
