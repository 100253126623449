/**
 *
 * useSiteConfigSelector
 *
 */

import { useSelector } from 'react-redux';
import { makeSelectSiteConfig } from 'containers/Main/selectors';

function useSiteConfigSelector(titles = []) {
  const siteConfigArr = useSelector(makeSelectSiteConfig());

  const relevantSiteConfig = titles.map(title => {
    const [siteConfig] = siteConfigArr.filter(item => item.title === title);
    return siteConfig;
  });

  return relevantSiteConfig;
}

export default useSiteConfigSelector;
