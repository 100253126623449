import { put, select, takeLatest } from 'redux-saga/effects';
import { getFirebase } from 'react-redux-firebase';
import _get from 'lodash/get';
import { makeSelectAnonymousId } from 'containers/Auth/selectors';
import { storeItemForSignIn } from 'containers/Auth/actions';
import { getLocalData } from 'utils/localDataStore';
import contentfulClient from 'utils/contentfulService';
import { getContentfulLocaleFilter } from 'containers/Main/utils';
import {
  GET_TAKEN_SERIES,
  MARK_SERIES_COMPLETED,
  MOVE_ANONYMOUS_SERIES,
  SAVE_LAST_VIEWED_RESOURCE,
  SAVE_RESOURCE_PROGRESS,
  SAVE_SERIES_COMPLETED_RESOURCES,
  SERIES_SAVE_CUSTOM_QUESTION,
} from './constants';
import {
  getTakenSeriesProcessing,
  getTakenSeriesSuccess,
  lastViewedResourceSaved,
  resourceProgressSaved,
  seriesCompleted,
  seriesCompletedResourcesSaved,
  seriesCustomQuestionSaved,
} from './actions';
import { formatSeriesContentfulEntries, getSeriesDoc } from './utils';

function* getTakenSeriesSaga() {
  try {
    const clientDetails = yield select(state => state.main.clientDetails);
    const excludeResourceTypes =
      _get(clientDetails, 'excludeResourceTypes') || [];

    if (excludeResourceTypes.includes('Series')) {
      return;
    }

    yield put(getTakenSeriesProcessing(true));

    const currentUser = yield getFirebase().auth().currentUser;
    const localeFilters = yield getContentfulLocaleFilter();
    const takenSeries = {};
    const seriesQuery = {
      content_type: 'series',
      'fields.reviewStatus[in]': 'Accepted',
      'fields.courseContent[ne]': 'Yes',
      select: [
        'sys.id',
        'fields.title',
        'fields.name',
        'fields.slug',
        'fields.style',
        'fields.image',
        'fields.time',
        'fields.resources',
        'fields.cardColor',
      ],
      limit: 1000,
      include: 1,
      ...localeFilters,
    };

    if (!currentUser) {
      const data = yield contentfulClient.getEntries(seriesQuery);
      const formattedData = formatSeriesContentfulEntries(data);
      yield put(
        getTakenSeriesSuccess({ takenSeries, allSeries: formattedData.items }),
      );
      return;
    }

    const [seriesCollection, allSeriesData] = yield Promise.all([
      getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(currentUser.uid)
        .collection('series')
        .get(),
      contentfulClient.getEntries(seriesQuery),
    ]);

    const allSeriesFormattedData = formatSeriesContentfulEntries(allSeriesData);

    seriesCollection.docs.forEach(doc => {
      const { id } = doc;

      if (allSeriesFormattedData.items.some(s => _get(s, 'sys.id') === id)) {
        const data = doc.data();
        const completedResourcesIds = data.completed_resources || [];
        const lastViewedResource = data.last_viewed_resource && {
          id: data.last_viewed_resource,
          completed: completedResourcesIds.includes(data.last_viewed_resource),
        };

        takenSeries[id] = {
          completedResourcesIds,
          completed: !!data.isComplete,
          completedAt: data.completed_at,
          resourcesProgress: data.resources_progress,
          lastViewedResource,
          seriesCustomQuestions: data.seriesCustomQuestions,
        };
      }
    });

    yield put(
      getTakenSeriesSuccess({
        takenSeries,
        allSeries: allSeriesFormattedData.items,
      }),
    );
  } catch (error) {
    yield put(getTakenSeriesProcessing(false));
  }
}

function* moveAnonymousSeriesSaga() {
  yield put(storeItemForSignIn({}));

  const userID = yield getFirebase().auth().currentUser.uid;
  const sessionAnonymousId = yield select(makeSelectAnonymousId());
  const anonymousId = getLocalData('userIdTemp') || sessionAnonymousId;

  const anonymousSeriesCollection = yield getFirebase()
    .firestore()
    .collection('anonymous_series')
    .doc(anonymousId)
    .collection('series')
    .get();

  const batch = getFirebase()
    .firestore()
    .batch();

  anonymousSeriesCollection.docs.forEach(doc => {
    const seriesDoc = getFirebase()
      .firestore()
      .collection('user_resources')
      .doc(userID)
      .collection('series')
      .doc(doc.id);

    batch.set(seriesDoc, doc.data());
    doc.ref.delete();
  });

  yield batch.commit();
}

function* markSeriesCompletedSaga({
  payload: { userId, seriesId, collection, completedResourcesIds },
}) {
  getSeriesDoc({
    firestore: getFirebase().firestore(),
    userId,
    seriesId,
    collection,
  }).set(
    {
      isComplete: true,
      completed_at: Date.now(),
      completed_resources: completedResourcesIds,
      resources_progress: getFirebase().firestore.FieldValue.delete(),
    },
    { merge: true },
  );

  yield put(seriesCompleted({ id: seriesId, completedResourcesIds }));
}

function* saveLastViewedResourceSaga({
  payload: { userId, seriesId, resourceId, collection, completed },
}) {
  getSeriesDoc({
    firestore: getFirebase().firestore(),
    userId,
    seriesId,
    collection,
  }).set({ last_viewed_resource: resourceId }, { merge: true });

  yield put(
    lastViewedResourceSaved({
      id: seriesId,
      resourceId,
      completed,
    }),
  );
}

function* saveSeriesCompletedResourcesSaga({
  payload: {
    completedResourcesIds,
    userId,
    seriesId,
    resourceId,
    collection,
    completed,
  },
}) {
  getSeriesDoc({
    firestore: getFirebase().firestore(),
    userId,
    seriesId,
    collection,
  }).set(
    {
      completed_resources: completedResourcesIds,
      resources_progress: {
        [resourceId]: null,
      },
      isComplete: false,
    },
    { merge: true },
  );

  yield put(
    seriesCompletedResourcesSaved({
      id: seriesId,
      resourceId,
      completedResourcesIds,
      completed,
    }),
  );
}

function* saveResourceProgressSaga({
  payload: { userId, seriesId, collection, seconds, resourceId },
}) {
  getSeriesDoc({
    firestore: getFirebase().firestore(),
    userId,
    seriesId,
    collection,
  }).set({ resources_progress: { [resourceId]: seconds } }, { merge: true });

  yield put(
    resourceProgressSaved({
      id: seriesId,
      resourceId,
      seconds,
    }),
  );
}

function* saveSeriesCustomQuestion({
  payload: { userId, seriesId, collection, question },
}) {
  const updatedFields = { seriesCustomQuestions: question };

  setTimeout(() => {
    getSeriesDoc({
      firestore: getFirebase().firestore(),
      userId,
      seriesId,
      collection,
    }).set(updatedFields, { merge: true });
  }, 200);

  yield put(seriesCustomQuestionSaved({ id: seriesId, question }));
}

export default function* defaultSaga() {
  yield takeLatest(
    SAVE_SERIES_COMPLETED_RESOURCES,
    saveSeriesCompletedResourcesSaga,
  );
  yield takeLatest(MARK_SERIES_COMPLETED, markSeriesCompletedSaga);
  yield takeLatest(SAVE_LAST_VIEWED_RESOURCE, saveLastViewedResourceSaga);
  yield takeLatest(GET_TAKEN_SERIES, getTakenSeriesSaga);
  yield takeLatest(MOVE_ANONYMOUS_SERIES, moveAnonymousSeriesSaga);
  yield takeLatest(SAVE_RESOURCE_PROGRESS, saveResourceProgressSaga);
  yield takeLatest(SERIES_SAVE_CUSTOM_QUESTION, saveSeriesCustomQuestion);
}
