import { getLocalData, setLocalData } from 'utils/localDataStore';
import { getSubdomain } from 'utils/stringUtils';

const getCustomOnboardingCacheKey = () => {
  const brand = getSubdomain() || getLocalData('brand');

  return `${brand}_customOnboardingViewed`;
};

export const getCustomOnboardingLocalUtils = () => {
  const cacheKey = getCustomOnboardingCacheKey();

  const get = () => getLocalData(cacheKey);
  const set = value => setLocalData(cacheKey, value);

  return {
    get,
    set,
  };
};
