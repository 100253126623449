import React, { useContext } from 'react';
import _classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { getImageFile, fallbackImage } from 'utils/stringUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _isEmpty from 'lodash/isEmpty';
import _defaultTo from 'lodash/defaultTo';
import { getAccentColor } from 'containers/Main/utils';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { setLeavingModal } from 'containers/Main/actions';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import { analyzeClientResource } from 'utils/analyzeClientResource';
import NavBarContext from '../../navBarContext';
import ViewLink from '../ViewLink';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    padding: props => (props.menu ? '0' : '17px'),
  },
  header: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  lists: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'flex-start',
  },
  image: {
    height: 'auto',
    maxHeight: '100%',
    objectFit: 'cover',
  },
  linkImage: {
    height: '100%',
  },
  listItem: {
    overflow: 'hidden',
    width: 192,
    marginBottom: 10,
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  clientLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 10,
  },
  clientLogo: {
    maxHeight: 45,
    maxWidth: 120,
  },
  imageWrapper: {
    display: 'flex',
    height: 104,
    position: 'relative',
  },
  typogragraphy: {
    display: '-webkit-box',
    margin: '0 auto',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    textAlign: 'left',
  },
  title: {
    '-webkit-line-clamp': '1',
    fontWeight: 'bold',
  },
  description: {
    '-webkit-line-clamp': '2',
  },
}));

function ClientResources() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locale = useAlgoliaLocale();
  const {
    clientResources,
    clientDetails,
    sendMixpanelEvent,
    siteCopy,
  } = useContext(NavBarContext);

  const hasClientResources = !_isEmpty(clientResources);
  const hasHomeResource =
    hasClientResources &&
    clientResources.some(r => (_get(r, 'show') || []).includes('Home'));

  const sortedResources = hasClientResources
    ? _orderBy(
        clientResources,
        [
          hasHomeResource ? r => _get(r, 'show', []).includes('Home') : null,
          hasHomeResource
            ? r => _defaultTo(_get(r, 'homeOrder'), Number.MIN_VALUE)
            : null,
          r => _defaultTo(_get(r, 'resourceOrder'), Number.MIN_VALUE),
        ].filter(Boolean),
        [
          hasHomeResource ? 'desc' : null,
          hasHomeResource ? 'desc' : null,
          'desc',
        ].filter(Boolean),
      )
    : clientResources;

  // const isSeparateLogoLayout = _get(
  //   clientDetails,
  //   'metadata.separateLogoLayout',
  //   false,
  // );
  // const clientLogo = isSeparateLogoLayout
  //   ? _get(clientDetails, 'crediblemindLogo.url')
  //   : _get(clientDetails, ['logoReverse', 'url']);
  const location = useLocation();
  const history = useHistory();

  if (!sortedResources) return null;
  // redirect
  const redirect = el => {
    const { article, name, type, exitDisclaimer } = el;

    const { isInternal, url } = analyzeClientResource(el);

    const clientName = clientDetails.name;

    if (!url && !isInternal) history.push('/404');

    if (isInternal) {
      sendMixpanelEvent('Menu Item - Clicked', {
        type: 'Client Exclusives',
        name: article || name,
        resource: url,
      });

      history.push(url);
    }

    if (!isInternal) {
      sendMixpanelEvent('Menu Item - Exited', {
        type: 'Client Exclusives',
        name: article || name,
        page: location.pathname,
        exitPage: url,
        client: clientName,
      });

      const finalUrl = url.includes('http') ? url : `https://${url}`;
      if (!finalUrl.includes('assets.ctfassets.net')) {
        dispatch(
          setLeavingModal({
            visible: true,
            title:
              getAlgoliaLocalizedField(el, 'article', locale) ||
              getAlgoliaLocalizedField(el, 'title', locale) ||
              getAlgoliaLocalizedField(el, 'name', locale),
            url: finalUrl,
            organization: clientName,
            resourceType: type || null,
            disclaimer: exitDisclaimer,
          }),
        );
      } else {
        window.open(finalUrl, '_blank', 'noopener');
      }
    }
  };

  const accentColor = getAccentColor(clientDetails, '01619B');
  const cardRootColor = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: `#${accentColor}`,
    opacity: 0.8,
    mixBlendMode: 'screen',
  };
  const shouldApplyBackground =
    _get(clientDetails, 'metadata.displayNewLinkCard', false) &&
    !_get(clientDetails, 'metadata.disableLinkColorOverlay', false);

  return (
    <div className={classes.root}>
      {/* {clientLogo && (
        <div className={classes.clientLogoWrapper}>
          <img
            src={clientLogo}
            alt="Co-brand Logo"
            className={classes.clientLogo}
          />
        </div>
      )} */}
      <div className={classes.lists}>
        {sortedResources.slice(0, 3).map(el => {
          const imageSrc = getImageFile(el);
          const { slug, article, name, title, shortDescription } = el;
          const isLink = _get(el, 'sys.contentType.sys.id') === 'link';

          return (
            <ButtonBase onClick={() => redirect(el)} key={slug}>
              <div className={classes.listItem}>
                <div className={classes.imageWrapper}>
                  <img
                    src={imageSrc}
                    alt={article || name || title}
                    className={_classNames(classes.image, {
                      [classes.linkImage]: isLink,
                    })}
                    onError={e => {
                      e.target.src = fallbackImage;
                    }}
                  />
                  {shouldApplyBackground && <div style={cardRootColor} />}
                </div>
                <div>
                  <Typography
                    className={_classNames(
                      classes.title,
                      classes.typogragraphy,
                    )}
                    color="textPrimary"
                  >
                    {article || name || title}
                  </Typography>
                  <Typography
                    className={_classNames(
                      classes.description,
                      classes.typogragraphy,
                    )}
                    color="textPrimary"
                  >
                    {shortDescription}
                  </Typography>
                </div>
              </div>
            </ButtonBase>
          );
        })}
      </div>
      <ViewLink
        label={_get(siteCopy, [
          'pageCopy',
          'submenu-client-resources',
          'view-all',
        ])}
        link="/user-resources"
      />
    </div>
  );
}

export default ClientResources;
