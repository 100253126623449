/**
 *
 * Routes
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import NotFoundPage from 'components/EmptyState';
import UnavailablePage from 'components/UnavailableState';
import InactivePage from 'components/InactiveState';
import { isLocalhost } from 'utils/networkUtils';
import { windowScrollTo } from 'utils/embedded';
import PrivateRoute from './PrivateRoute';
import RoutesList from './Routes';
import { PreviousLocationContext } from './PrevLocationContext';

// eslint-disable-next-line react/prefer-stateless-function
export class Routes extends React.Component {
  prevLocation = this.props.location;

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { pathname } = location;
    const { location: prevLocation } = prevProps;
    const { pathname: prevPathname } = prevLocation;

    if (pathname !== prevPathname) {
      if (
        !pathname.match(/assessments\/(.+)\/page\d/) ||
        pathname.replace(
          /assessments\/(.+)\/page\d/,
          (_, p1) => `assessments/${p1}`,
        ) !==
          prevPathname.replace(
            /assessments\/(.+)\/page\d/,
            (_, p1) => `assessments/${p1}`,
          )
      ) {
        windowScrollTo(0, 0);
      }
      this.trackPage(pathname);
    }
    this.prevLocation = location;
  }

  trackPage = page => {
    if (!isLocalhost) {
      setTimeout(() => {
        ReactGA4.set({
          page,
        });
        ReactGA4.send({ hitType: 'pageview', page });
      }, 2500);
    }
  };

  render() {
    const { clientDetails } = this.props;
    const signInRequired = _get(clientDetails, 'signIn') === 'Require';
    const isAICC = _get(clientDetails, 'aicc', false);
    const isAppEmbed = _get(clientDetails, 'metadata.useAppEmbedStyle', false);
    const isClientActive = _get(clientDetails, 'activeClient') ?? true;

    if (!isClientActive) {
      return (
        <Switch>
          {/* <Redirect from="*" to="/inactive" /> */}
          <Route component={InactivePage} />
        </Switch>
      );
    }

    return (
      <PreviousLocationContext.Provider value={this.prevLocation}>
        <Switch>
          {RoutesList.map(
            ({ exact, path, component, ignoreRequire, authRequire }) =>
              (!ignoreRequire && signInRequired) || authRequire ? (
                <PrivateRoute
                  exact={exact}
                  path={path}
                  key={path}
                  component={component}
                />
              ) : (
                <Route
                  exact={exact}
                  path={path}
                  component={component}
                  key={path}
                />
              ),
          )}
          <Route exact path="/404" component={NotFoundPage} />
          {(isAICC || isAppEmbed) && (
            <Route exact path="/unavailable" component={UnavailablePage} />
          )}
          <Redirect from="/company" to="/?showcorp=1" />
          <Route from="*" component={NotFoundPage} />
        </Switch>
      </PreviousLocationContext.Provider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  clientDetails: makeSelectClientDetails(),
});

const withConnect = connect(mapStateToProps);

Routes.propTypes = {
  clientDetails: PropTypes.object,
  location: PropTypes.object,
};

export default withConnect(withRouter(Routes));
