import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { regenerateAnonymousId } from 'containers/Auth/actions';
import { setIsEndingSession } from 'containers/Main/actions';
import { useDispatch } from 'react-redux';
import useSiteCopySelector from 'components/useSiteCopySelector';
import _get from 'lodash/get';
import { isPublicMode, removeLocalData } from '../../utils/localDataStore';

const DEFAULT_IDLE_TIME_IN_SEC = 1200; // 20 mins
const PROMPT_TIMEOUT_IN_MS = 5000; // 5 sec
const useIdleTimeout = ({
  onIdle,
  idleTime = DEFAULT_IDLE_TIME_IN_SEC,
  enable,
  setOpenInactivityDialog,
}) => {
  const [siteCopy] = useSiteCopySelector(['inactivity-modal']);
  const promptTimeoutInSec = _get(
    siteCopy,
    'pageCopy.inactivityModalExpirationTime',
    0,
  );
  const promptTimeoutInMs = promptTimeoutInSec * 1000 || PROMPT_TIMEOUT_IN_MS;
  // show up 10 sec earlier than timeout setting
  const finalIdleTime = idleTime > 20 ? idleTime - 10 : idleTime - 5;
  const isPublicDevice = isPublicMode();
  const idleTimeout = 1000 * finalIdleTime;
  const [isIdle, setIdle] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  // TODO: if we want to re-use this, pass this as props
  const handleIdle = () => {
    dispatch(setIsEndingSession(true));
    setIdle(true);
    removeLocalData('userIdTemp');
    dispatch(regenerateAnonymousId());
    dispatch(setIsEndingSession(false));
    setOpenInactivityDialog(false);
    history.replace(`${isPublicDevice ? '/?mode=public' : '/'}`, {
      showReminderOverlay: false,
    });
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: promptTimeoutInMs,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
    startManually: true,
  });

  useEffect(() => {
    if (enable) {
      idleTimer.start();
    } else {
      idleTimer.pause();
    }
  }, [enable, idleTimer]);
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
