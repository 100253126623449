import { takeLatest, call, put, select } from 'redux-saga/effects';
import client from 'utils/contentfulService';
import AlgoliaClient from 'utils/algoliaService';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { apiCatch } from 'utils/sentryApiCatch';
import { makeSelectProfileFilters } from 'containers/Auth/selectors';
import { makeSelectClientLanding } from 'containers/ClientLanding/selectors';
import {
  makeSelectClientDetails,
  makeSelectSiteConfig,
} from 'containers/Main/selectors';
import {
  getAlgoliaRatingQuery,
  getAlgoliaTopicsQuery,
  getContentfulRatingQuery,
  getContentfulTopicsQuery,
  getAlgoliaClientQuery,
  getAlgoliaFilters,
  getContentfulLocaleFilter,
} from 'containers/Main/utils';
import { getResourcesIndex } from 'containers/Resources/utils';
import {
  GET_HOME_ASSESSMENTS,
  GET_HOME_NEWS,
  GET_HOME_BLOGS,
} from './constants';
import {
  getHomeAssessmentsSuccess,
  processing,
  getHomeNewsSuccess,
  getHomeBlogsSuccess,
} from './actions';

let algoliaIndex;

function* getAlgoliaData(limit, typeFilters) {
  const ratingCutOffQuery = yield call(getAlgoliaRatingQuery);
  let audienceQuery = '';
  // 3rd param type boolean is use to indicate if filters is only for excluded topic
  const topicsQuery = yield call(getAlgoliaTopicsQuery, 'filterTopics', false);
  const clientDetails = yield select(makeSelectClientDetails());
  const profileFilters = yield select(makeSelectProfileFilters());
  if (!algoliaIndex) {
    const resourcesWeightings = _get(
      clientDetails,
      'metadata.algoliaResourcesWeight',
    );
    algoliaIndex = AlgoliaClient.initIndex(
      getResourcesIndex(resourcesWeightings),
    );
  }
  const excludedAssessments = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
  );
  const {
    algoliaAssessmentsQuery = '',
    algoliaRequiredTagsQuery,
    algoliaStateQuery,
  } = getAlgoliaFilters({
    excludedAssessments,
    profileFilters,
  });
  const clientLandingInfo = yield select(makeSelectClientLanding());
  const audienceTypes = _get(clientLandingInfo, 'audienceTypeCollection.items');
  audienceQuery = !_isEmpty(audienceTypes)
    ? audienceTypes.map(item => `audience:${_get(item, 'sys.id')}`)
    : '';
  const articlesQueryOnly = `AND ${getAlgoliaClientQuery(clientDetails)}`;
  const queryParams = {
    hitsPerPage: limit,
    filters: `reviewStatus:'Accepted' ${ratingCutOffQuery} ${topicsQuery} ${typeFilters} ${articlesQueryOnly} ${algoliaAssessmentsQuery} ${algoliaRequiredTagsQuery} ${algoliaStateQuery}`,
    optionalFilters: audienceQuery[0],
  };
  const response = yield algoliaIndex.search('', queryParams);
  return response;
}

function* getHomeAssessmentsSaga({ payload }) {
  try {
    yield put(processing(true));
    const { limit } = payload;
    const typeFilters = 'AND type:Assessments';
    const response = yield call(getAlgoliaData, limit, typeFilters);
    yield put(
      getHomeAssessmentsSuccess({
        data: response.hits,
      }),
    );
  } catch (error) {
    errorHandler(error);
  } finally {
    yield put(processing(false));
  }
}

function* getHomeNewsSaga() {
  const currentLocaleFilters = yield select(state =>
    _get(state, 'homepage.newsLanguage'),
  );
  const currentHomePageNews = yield select(state =>
    _get(state, 'homepage.news'),
  );
  const localeFilters = yield call(getContentfulLocaleFilter);
  if (
    _isEmpty(currentHomePageNews) ||
    currentLocaleFilters !== localeFilters.locale
  ) {
    try {
      yield put(processing(true));
      const clientDetails = yield select(makeSelectClientDetails());
      const siteConfig = yield select(makeSelectSiteConfig());
      const [featuresConfig] = siteConfig.filter(
        item => item.title === 'Features',
      );

      const contentfulRatingQuery = yield call(getContentfulRatingQuery);
      const contentfulTopicsQuery = yield call(getContentfulTopicsQuery);
      const wordCountFilters = {};
      const shouldFilterShortArticles = _get(
        clientDetails,
        'metadata.filterShortArticles',
        false,
      );
      if (shouldFilterShortArticles) {
        wordCountFilters['fields.wordCount[gte]'] = _get(
          featuresConfig,
          'config.articlesMinimumWordCount',
          400,
        );
      }

      const popular = yield call(() =>
        client.getEntries({
          content_type: 'activity',
          'fields.reviewStatus': 'Accepted',
          order: '-fields.publicationDate',
          'fields.type': 'News',
          limit: 5,
          include: 1,
          ...wordCountFilters,
          ...contentfulRatingQuery,
          ...contentfulTopicsQuery,
          ...localeFilters,
        }),
      );
      yield put(
        getHomeNewsSuccess({
          type: 'popular',
          data: popular.items,
          locale: localeFilters.locale,
        }),
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      yield put(processing(false));
    }
  }
}

function* getHomeBlogsSaga() {
  const currentLocaleFilters = yield select(state =>
    _get(state, 'homepage.blogsLanguage'),
  );
  const currentHomePageBlogs = yield select(state =>
    _get(state, 'homepage.blogs'),
  );
  const localeFilters = yield call(getContentfulLocaleFilter);
  if (
    _isEmpty(currentHomePageBlogs) ||
    currentLocaleFilters !== localeFilters.locale
  ) {
    try {
      yield put(processing(true));

      const contentfulRatingQuery = yield call(getContentfulRatingQuery);
      const contentfulTopicsQuery = yield call(getContentfulTopicsQuery);

      const response = yield call(() =>
        client.getEntries({
          content_type: 'activity',
          'fields.reviewStatus': 'Accepted',
          limit: 3,
          include: 1,
          'fields.blog': true,
          order: '-fields.publicationDate',
          ...contentfulRatingQuery,
          ...contentfulTopicsQuery,
          ...localeFilters,
        }),
      );
      yield put(
        getHomeBlogsSuccess({
          data: response.items,
          locale: localeFilters.locale,
        }),
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      yield put(processing(false));
    }
  }
}

/**
 *
 * @param {algolia/contentful error data} error
 */
function errorHandler(error) {
  apiCatch(error);
}

export default function* defaultSaga() {
  yield takeLatest(GET_HOME_ASSESSMENTS, getHomeAssessmentsSaga);
  yield takeLatest(GET_HOME_NEWS, getHomeNewsSaga);
  yield takeLatest(GET_HOME_BLOGS, getHomeBlogsSaga);
}
