import differenceInYears from 'date-fns/differenceInYears';
import _every from 'lodash/every';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import { sha256 } from 'js-sha256';
import {
  visibleIfRegex,
  visibleIfContainsRegex,
  visibleIfContainsWoBracketRegex,
  parenthesisRegex,
} from './constants';

export const getHashedUserId = userId => sha256(sha256(sha256(sha256(userId))));

const getAssessmentJSON = (
  assessment,
  lastAnswer,
  isRetake,
  profile,
  insuranceQuestionNames,
  excludedQuestions,
) => {
  const json = JSON.parse(assessment.json);
  if (isRetake && lastAnswer) {
    const newPages = json.pages.map(page => {
      const newElements = page.elements.map(element => ({
        ...element,
        defaultValue: lastAnswer.result[element.name],
      }));

      const newPage = page;
      newPage.elements = newElements;
      return newPage;
    });

    json.pages = newPages;
    return removeGenderAgeIndustryQuestions(
      json,
      profile,
      excludedQuestions,
      insuranceQuestionNames,
    );
  }

  return removeGenderAgeIndustryQuestions(
    json,
    profile,
    excludedQuestions,
    insuranceQuestionNames,
  );
};

const removeGenderAgeIndustryQuestions = (
  assessment,
  profile,
  excludedQuestions = [],
  insuranceQuestionNames = [],
) => {
  // TODO: Remove when month/year approach spreads
  if (profile.isLoaded) {
    if (excludedQuestions.length || !_isEmpty(profile.insurance)) {
      const pages = assessment.pages.map(page => {
        const newElements = page.elements.map(el => {
          if (
            excludedQuestions.includes(el.name) ||
            (!_isEmpty(profile.insurance) &&
              insuranceQuestionNames.includes(el.name))
          ) {
            const newElement = el;
            newElement.visible = false;

            return newElement;
          }

          const newElement = el;
          newElement.visible = true;

          return el;
        });

        if (newElements.filter(el => el.visible).length === 0) return null;

        return {
          ...page,
          elements: newElements,
        };
      });

      const newAssessment = assessment;
      newAssessment.pages = pages.filter(page => page !== null);

      return newAssessment;
    }
    return assessment;
  }
  return assessment;
};

const getDataFromResult = (result, name, customDemographics = {}) => {
  const data = result[name];

  if (customDemographics[name]) {
    const [answer] = customDemographics[name].choices.filter(
      choice => choice.value === data,
    );
    if (answer) return answer.readableValue;
  }

  return data || '';
};

const getCleanResult = result => {
  const cleanResult = { ...result };

  Object.keys(cleanResult).forEach(key => {
    if (key.includes('other-option/')) {
      const question = key.split('other-option/')[1];
      if (Array.isArray(cleanResult[question])) {
        cleanResult[question] = cleanResult[question]
          .concat(cleanResult[key])
          .filter(item => item !== 'other-option');
      } else {
        cleanResult[question] = cleanResult[key];
      }
      delete cleanResult[key];
    }
  });
  // delete cleanResult.genderStandard;
  // delete cleanResult.ageRangeStandard;
  // delete cleanResult['genderStandard-Comment'];

  return cleanResult;
};

const convertToPattern = (fieldName, value, siteConfig, clientShortName) => {
  // TODO: Remove when month/year approach spreads
  if (fieldName === 'dob' || fieldName === 'gender') {
    const config = _get(
      siteConfig,
      ['config', fieldName === 'dob' ? 'age' : fieldName],
      {},
    );
    const mapping = {};
    if (_get(config, clientShortName)) {
      _get(config, clientShortName, []).forEach(item => {
        mapping[item.answerValue] = item.value;
      });
    } else {
      _get(config, 'default', []).forEach(item => {
        mapping[item.answerValue] = item.value;
      });
    }
    return mapping[value] || value;
  }
  if (fieldName === 'industry') {
    switch (value) {
      case '1':
        return 'A student';
      case '2':
        return 'Working in healthcare';
      case '3':
        return 'Working in education or childcare';
      case '4':
        return 'Working in service, retail or gig economy';
      case '5':
        return 'Working in other private sector';
      case '6':
        return 'Working in other government';
      case '7':
        return 'Working in other non-profit';
      case '8':
        return 'Unemployed';
      case '9':
        return 'Retired';
      default:
        return value;
    }
  }
  if (fieldName === 'insurance') {
    const mapping = {};
    _get(siteConfig, 'config.options', []).forEach(item => {
      const clients = _get(item, 'client', []);
      if (clients.includes(clientShortName)) {
        mapping[item.answerValue] = item.value;
      }
    });
    if (Array.isArray(value)) {
      return value.map(item => mapping[item] || item);
    }
    return mapping[value] || value;
  }
  return null;
};

const getNumberOfQuestions = data => {
  let len = 0;
  data.pages.forEach(item => {
    len += _get(item, 'elements', []).length;
  });

  return len;
};

const getQuestionVisibility = (question, answers, isPage = false) => {
  if (question.visible === false) return false;

  if (_has(question, 'visibleIf')) {
    const definitiveExpression = question.visibleIf
      .replace(/OR/g, 'or')
      .replace(/AND/g, 'and');
    const relatedAnswers = definitiveExpression.match(visibleIfRegex);
    const relatedAnswersExist = relatedAnswers.every(
      ans => _get(answers, ans.substring(1, ans.length - 1)) !== undefined,
    );
    const atLeastOneRelatedAnswerExist = relatedAnswers.some(
      ans => _get(answers, ans.substring(1, ans.length - 1)) !== undefined,
    );
    if (relatedAnswersExist || (isPage && atLeastOneRelatedAnswerExist)) {
      const { parsedExpression, parsedAnswers } = recursivelyParseParenthesis(
        definitiveExpression,
        parseMonthYearDate(answers),
      );
      return getCompositeExpressionValue(
        parsedExpression,
        parsedAnswers,
        false,
        isPage,
      );
    }
    return isPage;
  }
  return true;
};

const parseMonthYearDate = answers => {
  if (!answers.ageMonthYearStandard) return answers;
  if (typeof answers.ageMonthYear !== 'string') return answers;

  const parsedAnswers = { ...answers };
  parsedAnswers.ageMonthYearStandard = differenceInYears(
    Date.now(),
    new Date(
      answers.ageMonthYearStandard.split('-')[0],
      answers.ageMonthYearStandard.split('-')[1],
    ),
  );
  return parsedAnswers;
};

const getResultsVisibility = (expression, answers, debug = false) => {
  if (expression === '') return false;

  const definitiveExpression = expression
    .replace(/OR/g, 'or')
    .replace(/AND/g, 'and');
  const relatedAnswers = definitiveExpression.match(visibleIfRegex);
  const relatedAnswersExist = relatedAnswers.some(
    ans => _get(answers, ans.substring(1, ans.length - 1)) !== undefined,
  );

  if (relatedAnswersExist) {
    const { parsedExpression, parsedAnswers } = recursivelyParseParenthesis(
      definitiveExpression,
      parseMonthYearDate(answers),
      debug,
    );
    if (debug) console.log({ parsedExpression, parsedAnswers });
    return getCompositeExpressionValue(parsedExpression, parsedAnswers, debug);
  }
  return false;
};

function recursivelyParseParenthesis(expression, answers, debug) {
  let count = 0;
  let parsedExpression = expression;
  let parenthesisExpressions = (
    parsedExpression.match(parenthesisRegex) || []
  ).map(expr => expr.substring(1, expr.length - 1));
  const parsedAnswers = { ...answers };
  while (!_isEmpty(parenthesisExpressions)) {
    if (debug) console.log({ parenthesisExpressions });
    const parenthesisValues = parenthesisExpressions.map(expr =>
      getCompositeExpressionValue(expr, parsedAnswers, debug),
    );
    if (debug) console.log({ parenthesisValues });
    for (let p = 0; p < parenthesisExpressions.length; p += 1) {
      parsedExpression = parsedExpression.replace(
        `(${parenthesisExpressions[p]})`,
        `{parenthesis${count}} = '1'`,
      );
      parsedAnswers[`parenthesis${count}`] = parenthesisValues[p] ? '1' : '0';
      count += 1;
    }

    parenthesisExpressions = (
      parsedExpression.match(parenthesisRegex) || []
    ).map(expr => expr.substring(1, expr.length - 1));
  }

  return {
    parsedExpression,
    parsedAnswers,
  };
}

function getCompositeExpressionValue(
  expression,
  answers,
  debug,
  isPage = false,
) {
  const valuesMap = {};
  const values = expression.match(/(?:['"])(?:[^'"]+)(?:['"])/g);
  let convertedExpression = expression;
  if (values && values.length > 0) {
    values.forEach(value => {
      const key = Math.floor((1 + Math.random()) * 0x1000000)
        .toString(16)
        .substring(1);
      valuesMap[key] = value.substring(1, value.length - 1);

      convertedExpression = convertedExpression.replace(
        new RegExp(value, 'g'),
        `"${key}"`,
      );
    });
  }

  const pieces = convertedExpression.split(/(\s+or\s+|\s+and\s+)/g);
  if (pieces.length === 1) {
    return getExpressionValue(pieces[0], answers, valuesMap, debug, isPage);
  }
  const conditions = pieces.map(piece =>
    getExpressionValue(piece, answers, valuesMap, debug, isPage),
  );
  if (debug) console.log({ pieces, valuesMap, conditions });
  let op = null;
  let result = null;
  for (let i = 0; i < conditions.length; i += 1) {
    if (i === 0) result = conditions[i];
    else if (typeof conditions[i] === 'string') {
      if (conditions[i].includes('or')) {
        op = 'or';
      } else if (conditions[i].includes('and')) {
        op = 'and';
      }
    } else if (typeof conditions[i] === 'boolean') {
      if (op === 'or') {
        result = result || conditions[i];
        op = null;
      } else if (op === 'and') {
        result = result && conditions[i];
        op = null;
      }
    }
  }
  if (debug) console.log({ result });
  return result;
}

function getExpressionValue(
  expression,
  answers,
  valuesMap = {},
  debug,
  isPage = false,
) {
  if (debug) console.log({ expression, answers });
  if (expression.includes(' or ') || expression.includes(' and '))
    return expression;

  const relatedAnswers = expression.match(visibleIfRegex);
  const match = expression.match(/(\[.*\])/g);
  if (debug) console.log({ expression, relatedAnswers, match });
  if (
    !_get(answers, relatedAnswers[0].substring(1, relatedAnswers[0].length - 1))
  )
    return isPage;
  if (!expression.includes('contains') && match && match.length > 0) {
    const possibleValues = match[0]
      .substring(1, match[0].length - 1)
      .replace(/"/g, '')
      .split(',');
    return _some(possibleValues, value =>
      _get(
        answers,
        relatedAnswers[0].substring(1, relatedAnswers[0].length - 1),
      ).includes(valuesMap[value]),
    );
  }

  if (expression.includes('>=')) {
    const expressionValue = expression.split(' >= ')[1].replace(/["']/g, '');
    return (
      relatedAnswers.reduce(
        (acc, value) =>
          acc + Number(_get(answers, value.substring(1, value.length - 1))),
        0,
      ) >= Number(valuesMap[expressionValue] || expressionValue)
    );
  }
  if (expression.includes('<=')) {
    const expressionValue = expression.split(' <= ')[1].replace(/["']/g, '');
    return (
      relatedAnswers.reduce(
        (acc, value) =>
          acc + Number(_get(answers, value.substring(1, value.length - 1))),
        0,
      ) <= Number(valuesMap[expressionValue] || expressionValue)
    );
  }
  if (expression.includes('=')) {
    let expressionValue = expression.split(' = ')[1].replace(/["']/g, '');
    if (expressionValue === 'true') {
      expressionValue = '1';
    } else if (expressionValue === 'false') {
      expressionValue = '0';
    }
    return (
      relatedAnswers.reduce(
        (acc, value) =>
          acc + Number(_get(answers, value.substring(1, value.length - 1))),
        0,
      ) === Number(valuesMap[expressionValue] || expressionValue)
    );
  }
  if (expression.includes('<>')) {
    let expressionValue = expression.split(' <> ')[1].replace(/["']/g, '');
    if (expressionValue === 'true') {
      expressionValue = '1';
    } else if (expressionValue === 'false') {
      expressionValue = '0';
    }
    return (
      relatedAnswers.reduce(
        (acc, value) =>
          acc + Number(_get(answers, value.substring(1, value.length - 1))),
        0,
      ) !== Number(valuesMap[expressionValue] || expressionValue)
    );
  }
  if (expression.includes('>')) {
    const expressionValue = expression.split(' > ')[1].replace(/["']/g, '');
    return (
      relatedAnswers.reduce(
        (acc, value) =>
          acc + Number(_get(answers, value.substring(1, value.length - 1))),
        0,
      ) > Number(valuesMap[expressionValue] || expressionValue)
    );
  }
  if (expression.includes('<')) {
    const expressionValue = expression.split(' < ')[1].replace(/["']/g, '');
    return (
      relatedAnswers.reduce(
        (acc, value) =>
          acc + Number(_get(answers, value.substring(1, value.length - 1))),
        0,
      ) < Number(valuesMap[expressionValue] || expressionValue)
    );
  }
  if (visibleIfContainsRegex.test(expression)) {
    visibleIfContainsRegex.lastIndex = 0;
    const elementsString = expression.split(' contains ')[1];
    const elementsValues = elementsString
      .substring(1, elementsString.length - 1)
      .split(',')
      .map(value => value.substring(1, value.length - 1));
    if (debug)
      console.log('visibleIfContains', {
        relatedAnswers,
        elementsString,
        elementsValues,
      });
    return _every(elementsValues, value =>
      _get(
        answers,
        relatedAnswers[0].substring(1, relatedAnswers[0].length - 1),
      ).includes(valuesMap[value] || value),
    );
  }
  if (visibleIfContainsWoBracketRegex.test(expression)) {
    visibleIfContainsWoBracketRegex.lastIndex = 0;
    const elementsString = expression.split(' contains ')[1];
    const elementsValues = elementsString
      .split(',')
      .map(value => value.substring(1, value.length - 1));
    if (debug) console.log({ elementsString, elementsValues });
    return _every(elementsValues, value =>
      _get(
        answers,
        relatedAnswers[0].substring(1, relatedAnswers[0].length - 1),
      ).includes(valuesMap[value] || value),
    );
  }
  if (relatedAnswers[0].includes('parenthesis')) {
    return answers[relatedAnswers[0]];
  }
  return true;
}

const getAssessmentReferrals = (referrals, clientShortName, assessmentId) => {
  const clientAssessmentReferral = referrals.filter(r => {
    const clientMatches = _get(r, 'clientCollection.items', []).some(
      item => _get(item, 'shortName') === clientShortName,
    );
    if (!clientMatches) {
      return false;
    }
    return _get(r, 'showCollection.items', []).some(
      item => _get(item, 'sys.id') === assessmentId,
    );
  });
  const defaultAssessmentReferral = referrals.find(r => {
    if (r.slug !== 'default-assessment-under-age') {
      return false;
    }

    if (!_isEmpty(_get(r, 'clientCollection.items', []))) {
      return _get(r, 'clientCollection.items').some(
        item => _get(item, 'shortName') === clientShortName,
      );
    }

    if (_isEmpty(_get(r, 'excludeClientCollection.items', []))) {
      return false;
    }

    return !_get(r, 'excludeClientCollection.items', []).some(
      c => _get(c, 'shortName') === clientShortName,
    );
  });

  return clientAssessmentReferral.concat(defaultAssessmentReferral);
};

const getAssessmentExcludedQuestions = (
  profile,
  profileFields,
  ageGenderMappingSiteConfig,
) => {
  const profileFieldsMapping = _get(
    ageGenderMappingSiteConfig,
    'pageCopy.profileFieldToQuestionName',
  ) || {
    birthdayMonthYear: ['ageMonthYearStandard'],
    dob: ['ageRangeStandard'],
    gender: ['genderStandard', 'genderLGBTQStandard'],
    industry: ['industryStandard'],
    stateOfResidence: ['stateOfResidence'],
  };

  const fieldsToCheck = profileFields || Object.keys(profileFieldsMapping);
  return Object.keys(profileFieldsMapping)
    .flatMap(key => {
      if (profile[key] || !fieldsToCheck.includes(key)) {
        return profileFieldsMapping[key];
      }

      return null;
    })
    .filter(Boolean);
};

export const getScorePercentage = (
  { maxValue, minValue, valueDirection },
  overallScoring,
) => {
  if (valueDirection === 'higherBetter') {
    return (overallScoring / (maxValue - minValue)) * 100;
  }

  return ((maxValue - minValue - overallScoring) / maxValue) * 100;
};

export {
  getAssessmentJSON,
  removeGenderAgeIndustryQuestions,
  getDataFromResult,
  getCleanResult,
  convertToPattern,
  getNumberOfQuestions,
  getQuestionVisibility,
  getResultsVisibility,
  getAssessmentReferrals,
  getAssessmentExcludedQuestions,
};
