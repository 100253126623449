/**
 *
 * useNPSTriggers
 *
 */

import { useEffect } from 'react';
import update from 'immutability-helper';
import _get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { makeSelectProfile } from 'containers/Auth/selectors';
import {
  makeSelectClientDetails,
  makeSelectNPSPlayCount,
  makeSelectSiteCopy,
} from 'containers/Main/selectors';
import { setNPSVisibility } from 'containers/Main/actions';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import { DEFAULT_CONFIG } from './constants';

const useQuery = () => new URLSearchParams(useLocation().search);

function useNPSTriggers() {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const query = useQuery();
  const profile = useSelector(makeSelectProfile());
  const npsPlayCount = useSelector(makeSelectNPSPlayCount());
  const siteCopy = useSelector(makeSelectSiteCopy());
  const clientDetails = useSelector(makeSelectClientDetails());

  const utmSource = query.get('utm_source');

  const npsSiteCopy = _get(
    siteCopy.filter(el => el.slug === 'nps')[0],
    'pageCopy',
    {},
  );
  const npsClient = _get(clientDetails, 'metadata.nps', {});
  const finalNPS = update(npsSiteCopy, {
    $merge: npsClient,
  });

  const activeMinutes = _get(
    finalNPS,
    'config.activeMinutes',
    DEFAULT_CONFIG.activeMinutes,
  );
  const daysForRetake = _get(
    finalNPS,
    'config.daysForRetake',
    DEFAULT_CONFIG.daysForRetake,
  );
  const playCountSession = _get(
    finalNPS,
    'config.playCountSession',
    DEFAULT_CONFIG.playCountSession,
  );
  const playCountTotal = _get(
    finalNPS,
    'config.playCountTotal',
    DEFAULT_CONFIG.playCountTotal,
  );

  useEffect(() => {
    if (profile.isLoaded) {
      let sessionTime = 0;

      const authPathnames = ['/login', '/signup', '/forgot_password'];
      const interval = setInterval(() => {
        if (
          document.visibilityState === 'visible' &&
          !authPathnames.includes(document.location.pathname)
        ) {
          sessionTime += 5000;

          if (sessionTime >= activeMinutes * 60 * 1000) {
            const lastNPS = profile.isEmpty
              ? getLocalData('npsDate') || 0
              : _get(profile, 'npsDate', 0);
            if (
              !_get(profile, 'email', '').includes('@crediblemind.com') &&
              Date.now() > lastNPS + daysForRetake * 24 * 60 * 60 * 1000
            )
              dispatch(
                setNPSVisibility({
                  visibility: true,
                  reason: 'active-time',
                }),
              );
            clearInterval(interval);
          }
        }
      }, 5000);

      return () => clearInterval(interval);
    }
    return () => null;
  }, [profile]);

  useEffect(() => {
    if (profile.isLoaded && utmSource === 'email') {
      const lastNPS = profile.isEmpty
        ? getLocalData('npsDate') || 0
        : _get(profile, 'npsDate', 0);
      if (
        !_get(profile, 'email', '').includes('@crediblemind.com') &&
        Date.now() > lastNPS + daysForRetake * 24 * 60 * 60 * 1000
      )
        dispatch(
          setNPSVisibility({
            visibility: true,
            reason: 'subscription',
          }),
        );
    }
  }, [profile, utmSource]);

  useEffect(() => {
    let npsPlayCountTotal = 0;

    if (npsPlayCount > 0) {
      if (profile.isEmpty) {
        const currentCount = getLocalData('npsPlayCount') || 0;
        setLocalData('npsPlayCount', currentCount + 1);
        npsPlayCountTotal = currentCount + 1;
      } else {
        firebase.updateProfile({
          npsPlayCount: (profile.npsPlayCount || 0) + 1,
        });
        npsPlayCountTotal = (profile.npsPlayCount || 0) + 1;
      }
    }

    if (npsPlayCountTotal > playCountTotal) {
      const lastNPS = profile.isEmpty
        ? getLocalData('npsDate') || 0
        : _get(profile, 'npsDate', 0);
      if (
        !_get(profile, 'email', '').includes('@crediblemind.com') &&
        Date.now() > lastNPS + daysForRetake * 24 * 60 * 60 * 1000
      )
        dispatch(
          setNPSVisibility({
            visibility: true,
            reason: 'play-total',
          }),
        );
    }

    if (npsPlayCount >= playCountSession) {
      const lastNPS = profile.isEmpty
        ? getLocalData('npsDate') || 0
        : _get(profile, 'npsDate', 0);
      if (
        !_get(profile, 'email', '').includes('@crediblemind.com') &&
        Date.now() > lastNPS + daysForRetake * 24 * 60 * 60 * 1000
      )
        dispatch(
          setNPSVisibility({
            visibility: true,
            reason: 'play-session',
          }),
        );
    }
  }, [npsPlayCount]);

  return null;
}

export default useNPSTriggers;
