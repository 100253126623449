import { useLocation } from 'react-router-dom';

const authPaths = [
  '/login',
  '/logincm',
  '/signup',
  '/signupcm',
  '/signup/data',
  '/signup/email',
  '/forgot_password',
];

const useIsAuthPath = () => {
  const location = useLocation();

  return authPaths.includes(location.pathname);
};

export default useIsAuthPath;
