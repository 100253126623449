/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getImageFile } from 'utils/stringUtils';
import _get from 'lodash/get';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import StyledLink from 'components/StyledLink';
import AssessmentItemSmall from 'components/AssessmentItemSmall';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import ViewLink from '../ViewLink';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    width: 260,
  },
  mobileCardRoot: {
    boxShadow: 'none !important',
    borderRadius: 0,
    border: `1px solid transparent`,
    textAlign: 'center',
    position: 'relative',
    backgroundColor: 'transparent',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 260,
    color: 'white',
    padding: '14px 18px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 189,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  },
  time: {
    height: 21,
    width: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    background: '#01619B',
    borderRadius: 2,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    marginBottom: 16,
  },
  rtWrapper: {
    '& p': {
      margin: 0,
    },
  },
  link: {
    ...theme.typography.button,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    textDecoration: 'none',
    justifyContent: 'flex-start',
    '& img': {
      marginLeft: 5,
    },
  },
  mobileName: {
    marginTop: 12,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
  },
}));

function AssesmentMainItem({ data, clickHandler }) {
  const classes = useStyles();
  const locale = useAlgoliaLocale();
  const [siteCopy] = useSiteCopySelector(['resource-item-page']);

  return (
    <>
      <Hidden xsDown>
        {data.map(el => (
          <div
            className={classes.cardRoot}
            data-test-id="assessment-new"
            key={el.slug}
          >
            <AssessmentItemSmall item={el} menuCard />
          </div>
        ))}
      </Hidden>

      <Hidden smUp>
        <Grid container spacing={2}>
          {data.map(el => {
            const imageSrc = getImageFile(el);
            const { slug, name, time } = el;
            return (
              <Grid item xs={6} key={slug}>
                <StyledLink link={`/assessments/${slug}`}>
                  <div
                    className={classes.mobileCardRoot}
                    style={{ backgroundImage: `url(${imageSrc})` }}
                  >
                    <div>
                      <Typography className={classes.time}>{`${time} ${
                        time > 1 ? 'MINS' : 'MIN'
                      }`}</Typography>
                    </div>
                  </div>
                  <Typography
                    variant="h5"
                    className={classes.mobileName}
                    color="textPrimary"
                  >
                    {getAlgoliaLocalizedField(el, 'name', locale)}
                  </Typography>
                  <ViewLink
                    label={_get(siteCopy, 'pageCopy.takeAssessmentLabel')}
                    link={`/assessments/${slug}`}
                    isTextOnly
                    clickHandler={() => clickHandler(name)}
                    mt={8}
                    button
                  />
                </StyledLink>
              </Grid>
            );
          })}
        </Grid>
      </Hidden>
    </>
  );
}

AssesmentMainItem.propTypes = {
  data: PropTypes.array,
  clickHandler: PropTypes.func,
};

export default AssesmentMainItem;
